import axios from 'axios'
import { Message, MessageBox } from 'element-ui'
import store from '../store'
import GLOBAL from "@/utils/global";

 function getCookie(c_name)
	  {
	  if (document.cookie.length>0)
	    {
	   let c_start=document.cookie.indexOf(c_name + "=")
	    if (c_start!=-1)
	      { 
	      c_start=c_start + c_name.length+1 
	   let   c_end=document.cookie.indexOf(";",c_start)
	      if (c_end==-1) c_end=document.cookie.length
	      return unescape(document.cookie.substring(c_start,c_end))
	      } 
	    }
	  return ""
	  }
// 创建axios实例
const service = axios.create({
 baseURL: GLOBAL.baseURL,//window.SITE_CONFIG.baseUrl,
  timeout: 15000 ,// 请求超时时间
})

// request拦截器
service.interceptors.request.use(config => {
    config.headers['token'] = localStorage.getItem('token') // 让每个请求携带自定义token 请根据实际情况自行修改
  console.log(config)
  return config
}, error => {
  // Do something with request error
  // console.log(error) // for debug
  Promise.reject(error)
})

// respone拦截器
service.interceptors.response.use(
  response => {
  /**
  * code为非200是抛错 可结合自己业务进行修改
  */

    const res = response.data
		 return response.data
    if (res.code !== 200) {
      Message({
        message: res.message,
        type: 'error',
        duration: 3 * 1000
      })
      // 401:未登录;
      if (res.code === 401||res.code === 403) {
        MessageBox.confirm('你已被登出，可以取消继续留在该页面，或者重新登录', '确定登出', {
          confirmButtonText: '重新登录',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          store.dispatch('FedLogOut').then(() => {
            location.reload()// 为了重新实例化vue-router对象 避免bug
          })
        })
      }
      return Promise.reject('error')
    } else {
      return response.data
    }
  },
  error => {
		if (error.response.status == 401) { // 401, token失效
		//  console.log('401')
		 window.location.href=process.env.BASE_img
		} else if (error.response.status == 500) {
		  Message({
		    showClose: true,
		    message: '服务繁忙，请稍后再试',
		    type: 'error',
		    duration: 1000
		  })
		}

    return Promise.reject(error)
  }
)

export default service
