<template>
  <el-dialog
    title="处方详情"
    :visible.sync="visible"
    width="1200px"
    class="detail-info"
  >
    <el-form class="recipel-info" label-width="100px">
      <el-form-item label="处方类型">
        <span v-if="dataForm.prescriptionType == 1"> 中药处方</span>
        <span v-if="dataForm.prescriptionType == 2"> 西药/中成药处方</span>
      </el-form-item>
      <el-form-item label="开方时间">
        <span v-if="dataForm.openingTime"> {{ dataForm.openingTime }}</span>
        <span v-else> - </span>
      </el-form-item>
      <el-form-item label="处方状态">
        <span v-if="dataForm.status == 3"> 未开方</span>
        <span v-if="dataForm.status == 1"> 已开方 </span>
      </el-form-item>
      <el-form-item label="处方金额">
        <span v-if="dataForm.prescriptionAmount">
          {{ dataForm.prescriptionAmount }}</span
        >
        <span v-else> - </span>
      </el-form-item>
      <el-form-item label="服药频次">
        <span v-if="dataForm.frequency"> {{ dataForm.frequency }}</span>
        <span v-else> - </span>
      </el-form-item>
      <el-form-item label="总量">
        <span v-if="dataForm.total"> {{ dataForm.total }}付</span>
        <span v-else> - </span>
      </el-form-item>
      <el-form-item label="服药要求">
        <span v-if="dataForm.medicationRequirements">
          {{ dataForm.medicationRequirements }}</span
        >
        <span v-else> - </span>
      </el-form-item>
    </el-form>
    <div class="drug-info">
      <div class="drug-info-title">药品信息</div>
      <el-table :data="list" v-if="dataForm.prescriptionType == 1">
        <el-table-column label="序号" type="index" width="80"></el-table-column>
        <el-table-column label="药品名称" prop="drugsName"></el-table-column>
        <el-table-column label="剂量">
          <template slot-scope="scope">{{
            scope.row.drugsDose ? scope.row.drugsDose : "-"
          }}</template>
        </el-table-column>
        <el-table-column label="单位">
          <template slot-scope="scope">{{
            scope.row.drugsUseUnit ? scope.row.drugsUseUnit : "-"
          }}</template>
        </el-table-column>
        <el-table-column label="特殊要求">
          <template slot-scope="scope">{{
            scope.row.medicationRequirements
              ? scope.row.medicationRequirements
              : "-"
          }}</template>
        </el-table-column>
      </el-table>
      <el-table :data="list" v-if="dataForm.prescriptionType == 2">
        <el-table-column label="序号" type="index" width="80"></el-table-column>
        <el-table-column label="药品名称" prop="drugsName"></el-table-column>
        <el-table-column label="单次剂量" width="100">
          <template slot-scope="scope"
            >{{ scope.row.singleDoses ? scope.row.singleDoses : "-"
            }}{{
              scope.row.drugsUseUnit ? scope.row.drugsUseUnit : "-"
            }}</template
          >
        </el-table-column>
        <el-table-column label="用法" width="">
          <template slot-scope="scope">{{
            scope.row.drugsUseType ? scope.row.drugsUseType : "-"
          }}</template>
        </el-table-column>
        <el-table-column label="用药频次" width="140">
          <template slot-scope="scope">{{
            scope.row.frequency ? scope.row.frequency : "-"
          }}</template>
        </el-table-column>
        <el-table-column label="天数" width="80">
          <template slot-scope="scope">{{
            scope.row.days ? scope.row.days : "-"
          }}</template>
        </el-table-column>
        <el-table-column label="总量" width="80">
          <template slot-scope="scope"
            >{{ scope.row.total ? scope.row.total : "-"
            }}{{ scope.row.totalUnit ? scope.row.totalUnit : "-" }}</template
          >
        </el-table-column>
        <el-table-column label="用药说明">
          <template slot-scope="scope">{{
            scope.row.drugsIllustrate ? scope.row.drugsIllustrate : "-"
          }}</template>
        </el-table-column>
      </el-table>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      dataForm: {},
      list: [],
    };
  },
  methods: {
    init(row) {
      this.visible = true;
      this.dataForm = row;
      this.list = row.mbglPrescriptionDrugsList;
      console.log(row);
    },
  },
};
</script>

<style lang="scss" scoped>
.detail-info {
  /deep/.el-dialog__body {
    padding: 30px 0;
  }
}

.recipel-info {
  overflow: hidden;
  border-bottom: 1px solid #eee;
  padding: 0 32px;
  .el-form-item {
    float: left;
    width: 50%;
    margin-bottom: 22px;
    /deep/.el-form-item__label {
      color: #999;
      padding-right: 16px;
      line-height: 22px;
    }
    /deep/.el-form-item__content {
      line-height: 22px;
    }
  }
}
.drug-info {
  padding: 0 32px;
  .drug-info-title {
    font-size: 18px;
    padding: 24px 0;
    border-bottom: 1px solid #eee;
  }
}
</style>
