<template>
  <div class="clinic">
    <!-- 表单 -->
    <el-form :inline="true" class="form-content" >
      <el-form-item>
        <el-input
          placeholder="姓名查询"
          v-model="form.patienceName"
          class="input-with-select"
          style="width:240px;"
        >
          <el-button
            slot="append"
            @click="getDataList()"
            icon="el-icon-search"
          ></el-button>
        </el-input>
      </el-form-item>
      <el-form-item label="性别">
        <el-select v-model="form.patienceSex" placeholder="请选择" style="width:240px;">
          <el-option label="男" value="1"></el-option>
          <el-option label="女" value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="诊断查询">
        <el-select  v-model="form.mainDiagnosis" placeholder="请选择" style="width:240px;" filterable="" clearable="">
          <el-option
            v-for="item in typeArr"
            :label="item.name"
            :value="item.value"
            :key="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="年龄区间">
        <el-input v-model="form.patienceAgeMin" style="width:114px;"></el-input>
        <div class="divider-line"></div>
        <el-input v-model="form.patienceAgeMax" style="width:114px;"></el-input>
      </el-form-item>
      <el-form-item label="访视时间">
        <el-date-picker
          v-model="form.visitTime"
          type="date"
          placeholder="开始时间"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
        <div class="divider-line"></div>
        <el-date-picker
          v-model="form.visitEndTime"
          type="date"
          placeholder="结束时间"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="分组">
        <el-select  v-model="form.groupingId" placeholder="请选择" style="width:240px;" filterable="" clearable="">
          <el-option
            v-for="item in groupingList"
            :label="item.labelName"
            :value="item.id"
            :key="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="getDataList()" type="primary">搜索</el-button>
        <el-button @click="reset()" type="reset">重置</el-button>
      </el-form-item>
    </el-form>
    <div class="search-list">
      <el-button type="primary" @click="handleClickTwo()">添加</el-button>
      <!-- <el-button type="primary" @click="showSendPackage(1)">发送方案</el-button> -->
      <!-- <el-button type="primary" >自动登记</el-button>
			<el-button type="primary">导入</el-button>
      <el-button type="primary">导出</el-button>-->
      <el-button
        type="danger"
        @click="deleteHandle"
        :disabled="dataListSelections.length <= 0"
        >删除</el-button
      >
    </div>
    <!-- 发送方案 -->
    <send-package
      v-if="sendPackageDialog"
      ref="sendPackage"
      @refreshDataList="getDataList"
    ></send-package>
    <!-- 发送方案 -->
    <el-table
      ref="multipleTable"
      :data="dataList"
      @selection-change="selectionChangeHandle"
      tooltip-effect="dark"
      style="width: 100%"
      @sort-change="sortChange"
      v-loading="dataListLoading"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column label="病例ID" prop="case_id">
        <template slot-scope="scope">
          {{ scope.row.case_id ? scope.row.case_id : "-" }}
        </template>
      </el-table-column>
      <el-table-column label="姓名" prop="patience_name" sortable="custom"></el-table-column>
      <el-table-column prop="patience_sex_name" label="性别"></el-table-column>
      <el-table-column prop="patience_age" label="年龄" sortable="custom"></el-table-column>
      <el-table-column
        prop="patience_link_tel"
        label="联系方式"
      ></el-table-column>
      <el-table-column
        prop="main_diagnosis_name"
        label="诊断"
      ></el-table-column>
      <el-table-column
        prop="visitInfoTime"
        label="最近访视日期"
        sortable="custom"
      ></el-table-column>
      <el-table-column
        prop="payload"
        label="操作"
        show-overflow-tooltip
        align="right"
        header-align="right"
      >
        <template slot-scope="scope">
          <el-button @click="handleClick(scope.row)" type="text" size="small"
            >详情</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageNo"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
  </div>
</template>

<script>
// import { getTeamsPatienceListApi } from "@/api/case";
import moment from "moment";
import SendPackage from "./analysis/components/sendPackage.vue";
import { idCardRegExp, idCardFunction, getAge } from "@/utils";
export default {
  components: {
    SendPackage,
  },
  data() {
    return {
      sendPackageDialog: false, //发送方案

      dataListSelections: [],
      currentPage4: 5,
      input3: "",
      form: {
        patienceName: "",

        patienceSex: "",

        mainDiagnosis: "",
        groupingId: ""
      },
      typeArr: [],
      tableData: [],
      pageNo: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false, //列表loading
      tabData: [], //备用列表，在调用数据时使用
      dataList: [], //列表数据
      oldList: [],
      dateTime: ["", ""],
      orderBy: "",//排序 1 姓名 2 年龄 3 访视时间
      orderNum: "",//1 升序 2 降序
      groupingList: [],//分组列表
    };
  },
  mounted() {
    this.getDataList();
    this.getPorjType();
    this.getGroupList()
  },
  methods: {
    sortChange(column) {
      this.dataList = []
      // console.log(column);
      if (column && column.order) {
        if (column.order === 'ascending') {
          this.orderNum = 1;
        } else if (column.order === 'descending') {
          this.orderNum = 2;
        }
        if (column.prop === 'patience_name') {//这里双重判断：该字段为升序时向后端传递升序参数，反之降序
          this.orderBy = 1;
        } else if (column.prop === 'patience_age') {
          this.orderBy = 2
        } else if (column.prop === 'visitInfoTime') {
          this.orderBy = 3
        }
        
      } else {
        this.orderBy = "";
        this.orderNum = "";
      }
      this.getDataList();
    },

    // async getDataList() {
    //   const res = await getTeamsPatienceListApi(this.params);
    //   console.log(res)
    // },
    // 发送方案弹窗
    showSendPackage(type, row) {
      let _this = this;
      let patienceIds = [];
      if (type == 1) {
        patienceIds = _this.dataListSelections.map((item) => {
          return item.patience_id;
        });
      } else {
        patienceIds.push(row.patience_id);
      }
      _this.sendPackageDialog = true;
      _this.$nextTick(() => {
        _this.$refs.sendPackage.init(patienceIds);
      });
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
    async deleteHandle() {
      this.$confirm("确定进行[删除]操作", "提示", {
        confirmButtonText: "确定",
        cancelButtonClass: "btn-custom-cancel",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const ids = [];
          this.dataListSelections.forEach((v) => {
            ids.push(v.id);
          });
          const id = ids.join(`,`);
          const { data: res } = await this.$httpAes({
            url: this.$httpAes.adornUrl(`/teamsPatience/delete?ids=${id}`),
            method: "post",
			 data: { stringParam1: id },
          });
          if (res.status) {
            this.$message({
              message: res.msg,
              type: "success",
              duration: 1500,
              onClose: () => {
                this.getDataList();
              },
            });
          }
        })
        .catch(() => {});
    },
    reset() {
      this.form = {
        patienceName: "",

        patienceSex: "",

        type: "",
      };
    },
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageNo = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageNo = val;
      this.getDataList();
    },
    getDataList() {
      let that = this;
      let teamsId = "";
      let enertyId = "";
      if (localStorage.currentTeam) {
        teamsId = JSON.parse(localStorage.currentTeam).id;
        enertyId = JSON.parse(localStorage.currentTeam).entityId;
      }
      let params ={
		  stringParam2:that.form.patienceName,
		   stringParam4:that.form.patienceSex,
		    stringParam5:that.form.mainDiagnosis,
			stringParam6:that.form.patienceAgeMin,
			stringParam7:that.form.patienceAgeMax,
			stringParam8:that.form.visitStartTime,
			stringParam9:that.form.visitEndTime,
			stringParam10:that.form.groupingId
		   
	  } 
	
      params.stringParam1 = teamsId;
      params.intParam1 = that.pageSize;
      params.intParam2 = that.pageNo;
      params.intParam3 = that.orderBy;
      params.intParam4 = that.orderNum;
      that.dataListLoading = true;
      that
        .$httpAes({
          url: that.$httpAes.adornUrl("/teamsPatience/selectTeamsPatience"),
          method: "post",
          data: params,
        })
        .then(({ data }) => {
          if (data.status) {
            data.data.forEach((val) => {
              // if (val.patience_id_card) {
              //   val.patience_sex_name = idCardFunction(val.patience_id_card, 2);
              // }
              if (val.patience_birth) {
                val.patience_age = getAge(val.patience_birth);
              } else {
                val.patience_birth = idCardFunction(val.patience_id_card, 1);
                val.patience_age = idCardFunction(val.patience_id_card, 3);
              }
              if (
                val.patience_sex_name == null ||
                val.patience_sex_name.length == 0
              ) {
                val.patience_sex_name = idCardFunction(val.patience_id_card, 2);
              }
            });
            // that.tabData = data.list;
            that.dataList = JSON.parse(JSON.stringify(data.data));
            console.log(that.dataList);

            that.dataListLoading = false;
            that.totalPage = data.totalCount;
          }
        });
    },
    getPorjType() {
      this.dataListLoading = true;
      this.$httpAes({
        url: this.$httpAes.adornUrl("/dict/list?type=主要诊断"),
        method: "post",
		data: {
		  stringParam1: "主要诊断",
		},
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          token: this.$cookie.get("token"),
        },
      }).then(({ data }) => {
        this.typeArr = data.data;
      });
    },
    async getGroupList() {
      const user = JSON.parse(localStorage.getItem("userInfo"));
      let teamsId = "";
      let enertyId = "";
      if (localStorage.currentTeam) {
        teamsId = JSON.parse(localStorage.currentTeam).id;
        enertyId = JSON.parse(localStorage.currentTeam).entityId;
      }
      let params = {
        stringParam11: teamsId,
        intParam1: 1000,
        intParam2: 1,
        intParam3: 2,
      };
      const { data: res } = await this.$httpAes({
        url: this.$httpAes.adornUrl("/mbglLabel/list"),
        method: "post",
        data: params,
      });
      if (res.status) {
        this.groupingList = res.data;
      }
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    handleClick(row) {
      if (row.patience_sex_name == "男") {
        row.patience_sex = 1;
      } else {
        row.patience_sex = 2;
      }
      localStorage.patientInfo = JSON.stringify(row);
      console.log(row);
      console.log(555, row);

      this.$router.push({
        name: "case-details",
        params: row,
      });
    },
    handleClickTwo() {
      this.$router.push("case/create");
    },
  },
};
</script>

<style lang="scss" scoped>
.clinic {
  position: relative;

  .el-form {
    // display: flex;

    .el-form-item {
      // display: flex;
      margin-right: 48px;
      white-space: nowrap;

      .el-form-item__content {
        width: 240px;
        height: 36px;
        border-radius: 4px;
        border: 1px solid #d9d9d9;
      }
    }
  }

  .button {
    // float: left;

    // .el-button {
    // 	padding: 8px 23px;
    // 	height: 36px;
    // 	background: #0099ff;
    // 	border-radius: 2px;
    // }
  }

  // .el-table {
  //   position: absolute;
  //   top: 144px;
  // }
  // .el-pagination {
  //   margin-top: 371px;
  //   .el-pager .number {
  //     width: 32px;
  //     height: 32px;
  //     border-radius: 2px;
  //     border: 1px solid #d9d9d9;
  //   }
  //   .number:hover {
  //     background-color: #0099ff;
  //   }
  // }
  .divider-line {
    width: 20px;
    height: 1px;
    background: #979797;
    display: inline-block;
    margin: 0 10px 0;
  }
}
</style>
