<template>
	<div style="background-color: #fff;height: 100vh;width: 100%;">


		<div class="form-box login-box" v-loading="loading">
	<img style="width: 2.65rem;height: .48rem;margin-top: 0.69rem;" src="../../assets/img/loginLogo2.png" alt="" />
			<!-- <div class="form-box-con">

				<div style="display: flex;margin-bottom: .12rem;display: flex;align-items: center;">
						<img style="width: .1rem; height: .1rem;margin-right: .1rem;" src="../../assets/img/user.png"  alt="" />
						<input  placeholder="用户名" class="inputArea" style="border-bottom: 1px solid rgb(241, 241, 241);" type="text" v-model="dataForm.account" />
				</div>
			<div style="display: flex;margin-bottom: .12rem;display: flex;align-items: center;">
					<img style="width: .1rem; height: .1rem;margin-right: .1rem;" src="../../assets/img/pwd.png"  alt="" />
					<input  placeholder="密码" class="inputArea" style="border-bottom: 1px solid rgb(241, 241, 241);" type="password" v-model="dataForm.password" />
			</div>
			<img  @click="getCaptcha" class="loginBtn" src="../../assets/img/loginBtn.png" alt="" />
					
			
			</div> -->
			
		</div>
		<div class="cardArea">
			<div style="font-size: .14rem;font-weight: bold;margin-bottom: .2rem;width: 100%;margin-left: .05rem;">选择专病库平台</div>
			<button @click="changeActive(1)" @mouseenter="onMouseEnter(1)" @mouseleave="onMouseLeave" v-if="activeIndex==1" class="cardItemActive">
				<img src="../../assets/img/index/icons11.png" alt="" />
				<div>EDC-RWS</div>
			</button>
			<div @click="changeActive(1)" @mouseenter="onMouseEnter(1)" @mouseleave="onMouseLeave" v-if="activeIndex!=1" class="cardItem">
				<img src="../../assets/img/index/icon1.png" alt="" />
				<div>EDC-RWS</div>
			</div>
			<div @click="changeActive(2)" @mouseenter="onMouseEnter(2)" @mouseleave="onMouseLeave"  v-if="activeIndex==2"  class="cardItemActive">
				<img src="../../assets/img/index/icons33.png" alt="" />
				<div>脑血管病</div>
			</div>
			<div @click="changeActive(2)" @mouseenter="onMouseEnter(2)" @mouseleave="onMouseLeave" v-if="activeIndex!=2"  class="cardItem">
				<img src="../../assets/img/index/icon2.png" alt="" />
				<div>脑血管病</div>
			</div>
			<div @click="changeActive(3)" @mouseenter="onMouseEnter(3)" @mouseleave="onMouseLeave" v-if="activeIndex==3"  class="cardItemActive">
				<img src="../../assets/img/index/icons44.png" alt="" />
				<div>妇科慢病</div>
			</div>
			<div @click="changeActive(3)" @mouseenter="onMouseEnter(3)" @mouseleave="onMouseLeave" v-if="activeIndex!=3"  class="cardItem">
				<img src="../../assets/img/index/icon3.png" alt="" />
				<div>妇科慢病</div>
			</div>
			<div @click="changeActive(4)" @mouseenter="onMouseEnter(4)" @mouseleave="onMouseLeave" v-if="activeIndex==4"  class="cardItemActive">
				<img src="../../assets/img/index/icons55.png" alt="" />
				<div>炎性肠炎病</div>
			</div>
			<div @click="changeActive(4)" @mouseenter="onMouseEnter(4)" @mouseleave="onMouseLeave" v-if="activeIndex!=4"  class="cardItem">
				<img src="../../assets/img/index/icon4.png" alt="" />
				<div>炎性肠炎病</div>
			</div>
			<div @click="changeActive(5)" @mouseenter="onMouseEnter(5)" @mouseleave="onMouseLeave" v-if="activeIndex==5"  class="cardItemActive">
				<img src="../../assets/img/index/icons66.png" alt="" />
				<div>慢阻肺</div>
			</div>
			<div @click="changeActive(5)" @mouseenter="onMouseEnter(5)" @mouseleave="onMouseLeave" v-if="activeIndex!=5"  class="cardItem">
				<img src="../../assets/img/index/icon5.png" alt="" />
				<div>慢阻肺</div>
			</div>
			<div @click="changeActive(6)" @mouseenter="onMouseEnter(6)" @mouseleave="onMouseLeave" v-if="activeIndex==6"  class="cardItemActive">
				<img src="../../assets/img/index/icons22.png" alt="" />
				<div>其它慢病</div>
			</div>
			<div @click="changeActive(6)" @mouseenter="onMouseEnter(6)" @mouseleave="onMouseLeave" v-if="activeIndex!=6"  class="cardItem">
				<img src="../../assets/img/index/icon6.png" alt="" />
				<div>其它慢病</div>
			</div>
			<div style="width: 100%;height: .69rem;line-height: .1rem;margin-left: .05rem; color: #9A9A9A;font-size: .06rem; display: flex;
            
            align-items: center;">
				黄山生命科学研究院·长三角真实世界研究中心<br />
				中安九方健康产业(安徽)有限公司 技术支持
			</div>
		
		</div>
		<div class="footer">
			<div style="color: #DADADA;margin-right: .12rem;">Copyright◎ 中安九方健康产业(安徽)有限公司 Allrightsreserved.v{{year}}</div>
			 <a style="color: #B9B9B9;cursor: pointer;" href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank"  title="皖ICP备17023624号-6">皖ICP备17023624号-6</a>
			
		</div>
		<!-- <div style="height: 50vh;width: 100%;background-color: rgb(239, 237, 237);">
			
		</div> -->
	</div>
</template>

<script>
	import Qs from "qs";
	var regPhone = /^1[34578]\d{9}$/,
		regPwd = /^[a-z|A-Z|0-9|\-|_]{4,20}$/;
	import {
		getUUID
	} from "../../utils/index";
	import MD5 from "js-md5";
	// 腾讯滑块验证(新建一个js文件)
	import TencentCaptcha from "@/assets/TencentCaptcha";
	export default {
		data() {
			/*var validatePhone = (rule, value, callback) => {
			    if (!value) {
			      callback(new Error('请输入手机号'));
			    } else if (!regPhone.test(value)) {
			      callback(new Error('手机号格式不正确'));
			    } else {
			      callback();
			    }
			  }*/
			var validatePwd = (rule, value, callback) => {
				if (!value) {
					callback(new Error("请输入密码"));
				}
				/*else if (!regPwd.test(value)){
				         callback(new Error('4-20位，字母、数字或_'));
				       }*/
				else {
					callback();
				}
			};
			return {
				usernameText: "", //用户名文本
				passwordText: "", //记住密码文本
				activeIndex:'',
				dataForm: {
					account: "",
					password: "",
					//codeImg: ''
				},
				year:'',
				dataRule: {
					account: [{
						required: true,
						message: "请输入用户名/手机号",
						trigger: "blur"
					}, ],
					password: [{
						required: true,
						validator: validatePwd,
						trigger: "blur"
					}],
					code: [{
						required: true,
						message: "请输入图形验证码",
						trigger: "blur"
					}, ],
				},
				userInfo: {},
				loading: false,
				uuid: "",
				appId: "2036675646",
				// doctorUnionId: "00ec8279761b4ea78697c182db4e7877",
				unionName: "",
			};
		},
		computed: {},
		mounted() {
			this.year = new Date().getFullYear()
			localStorage.currentTitle = "黄山生命科学研究院·长三角真实世界研究中心";
			document.title = "黄山生命科学研究院·长三角真实世界研究中心";
		},
		methods: {
			onMouseEnter(val){
				this.activeIndex = val
				console.log('onMouseEnter')
			},
			onMouseLeave(val){
				this.activeIndex =''
				console.log('onMouseLeave')
			},
			// 获取滑块验证码
			getCaptcha() {
				let _this = this;
if(this.dataForm.account==''||this.dataForm.password==''){
	this.$message.error('请输入用户名和密码')
	return
}

			
						this.$Captcha.show();
						this.$bus.$on("getTicket", (res) => {
							// 返回结果
							// ret         Int       验证结果，0：验证成功。2：用户主动关闭验证码。
							// ticket      String    验证成功的票据，当且仅当 ret = 0 时 ticket 有值。
							// CaptchaAppId       String    验证码应用ID。
							// bizState    Any       自定义透传参数。
							if (res.ret === 0) {
								let params = {
									randstr: res.randstr,
									ticket: res.ticket,
								};
								_this
									.$http({
										url: _this.$http.adornUrl("/bind/describeCaptchaResult"),
										method: "post",
										data: Qs.stringify(params),
									})
									.then(({
										data
									}) => {
										let captchaCode = JSON.parse(data.data).CaptchaCode;
										if (data.data && captchaCode === 1) {
											_this.dataFormSubmit();
										} else {
											_this.$message.error("滑块校验未通过，请重新尝试");
										}
									});
							} else {
								// 提示用户完成验证
							}
							this.$bus.$off("getTicket");
						});
				
			},
			changeActive(val){
				//window.location.href='https://www.docter.com.cn/#/login2?teamsId=6199932308314cfe8cab9377856d137b'
				this.$router.push({
					path:'/login2',
					query:{
						teamsId:'6199932308314cfe8cab9377856d137b'
					}
				})
				// this.activeIndex = val
				// if(this.activeIndex==2){
				// 	this.$router.push('/index')
				// }else if(this.activeIndex==3){
				// 	window.location.href='https://www.docter.com.cn/#/login2?teamsId=6199932308314cfe8cab9377856d137b'
				// }else{
				// 		this.$message.warning('专病库建设中...')
				// }
			},
			// 提交表单
			dataFormSubmit() {
				// 判断复选框是否被勾选; 勾选则调用配置Cookie方法
			
						let param = {
							account: this.dataForm.account,
							password: MD5(this.dataForm.password),
							// code: this.dataForm.code,
							// uuid: this.uuid,
							roleType: 1,
							unionId: this.doctorUnionId,
						};
						localStorage.removeItem("token");
						this.$http({
							url: this.$http.adornUrl("/organBusiness/login"),
							method: "post",
							data: Qs.stringify(param),
						}).then(({
							data
						}) => {
							if (data.status) {
								localStorage.userInfo = JSON.stringify(data.data.user);
								localStorage.dept = JSON.stringify(data.data.dept);
								localStorage.setItem("token", data.data.token);
								this.$cookie.set("hospitalId", data.data.user.deptPid);

								this.getGzsInfo();
							} else {
								this.$message.error(data.msg);
							}
					
				});
			},
			// 获取团队下的病种
			getGzsInfo() {
				let that = this;
				that
					.$http({
						url: that.$http.adornUrl("/organBusiness/info"),
						method: "get",
					})
					.then(({
						data
					}) => {
						if (data.data) {
							this.$store.commit("common/updateImageUrl", data.data.avatar);
							sessionStorage.organBusinessInfo = JSON.stringify(data.data);
							if (data.data.teamsManages && data.data.teamsManages.length) {
								that.teamsManages = data.data.teamsManages;
								localStorage.teamsManages = JSON.stringify(that.teamsManages);
								if (data.data.lastTeamId) {
									that.currentTeam = that.teamsManages.find((item) => {
										return item.id == data.data.lastTeamId;
									});
									localStorage.currentTeam = JSON.stringify(that.currentTeam);
									//that.currentTeam = JSON.parse(localStorage.currentTeam);
								} else {
									that.currentTeam = data.data.teamsManages[0];
									localStorage.currentTeam = JSON.stringify(
										data.data.teamsManages[0]
									);
								}
								this.showTeam = true;
								this.gzsFlag = true;

								localStorage.gzsFlag = true;
								// this.$router.push({ name: "home" });
								location.href = 'https://www.docter.com.cn/#/home'
								sessionStorage.loginType = "login";
							} else if (data.data.roleId == "e89325119fa411e89e43525400557296") {
								console.log("没工作室+管理员身份");
								sessionStorage.radio = 1
								sessionStorage.loginType = "login";
								// this.$router.push({ name: "home" });
								location.href = 'https://www.docter.com.cn/#/home'
							} else if (data.data.roleId != "e89325119fa411e89e43525400557296") {
								localStorage.gzsFlag = false;
								this.$message.error("您未加入任何工作室，无法访问");
							}
						}
					});
			},
			getUserInfo() {
				let that = this;
				that
					.$http({
						url: that.$http.adornUrl("/organBusiness/info"),
						method: "get",
					})
					.then(({
						data
					}) => {
						if (data.data) {
							if (data.data.avatar) {
								that.$store.commit("common/updateImageUrl", data.data.avatar);
							}
							if (data.data.teamsManages && data.data.teamsManages.length) {
								that.$store.commit(
									"common/updateTeamsManages",
									data.data.teamsManages
								);
								that.$store.commit(
									"common/updateCurrentTeam",
									data.data.teamsManages[0]
								);
								localStorage.teamsManages = JSON.stringify(
									data.data.teamsManages
								);
								localStorage.currentTeam = data.data.teamsManages[0];
							}
						}
					});
			},
		},
	};
</script>

<style lang="scss">
	.cardArea{
		display: flex;
		flex-wrap: wrap;
		margin-top: -.52rem;
		background-color: #fff;
		margin-left: calc(57.8% - 2.27rem);
		width:3.24rem;
		height:1.92rem;
		border:1px solid rgb(236, 236, 236,);
		box-sizing: border-box;
		padding: .2rem;
		box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
		.cardItemActive{
			cursor: pointer;
			display: flex;
			align-items: center;
			//justify-content: center;
			color:#fff ;
			width: .81rem;
			height: .23rem ;
			margin: .05rem;
			border:1px solid #1FD4AD;
			background-color: #1FD4AD;
				font-size:  .08rem;
				border-radius:5px;
			img{
				height: .1rem;
				width: .1rem;
				margin-right: .05rem;
				margin-left: .1rem;
			}
		}
		.cardItem{
			cursor: pointer;
			display: flex;
			align-items: center;
			//justify-content: center;
			color:#1FD4AD ;
			width: .81rem;
			height: .23rem ;
			margin: .05rem;
			border:1px solid #1FD4AD;
				font-size:  .08rem;
				border-radius:5px;
			img{
				height: .1rem;
				width: .1rem;
				margin-right: .05rem;
				margin-left: .1rem;
			}
		}
	}
	.login-box {
		height: 46vh;
		width: 100%;
		background: url("../../assets/img/hdbg2.jpg") center center no-repeat;
		background-size: 100% 100%;
		text-align: center;

		.title {
			text-align: center;
			height: 30px;
			font-size: 22px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #333333;
			line-height: 30px;
			margin-bottom: 40px;
		}

		.topLogo {
			width: 104px;
			height: 134px;

			margin-top: 57px;
		}
.inputArea{
	border-style: none;
	width: 1.75rem;
	height: .2rem;
	line-height: .2rem;
}
		.form-box-con {
			position: absolute;
			width: 2.5rem;
			height: 1rem;
			left: calc( 50% - 1.25rem);
			top: calc(50% - .5rem);
			background: #FFFFFF;
			box-shadow: 0px 1px 10px 0px rgba(49,70,94,0.2);
			border-radius: 6px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			
		}

		.form-box-con-title {
			height: 48px;
			font-size: 50px;
			font-family: AlimamaShuHeiTi;
			font-weight: 400;
			color: #FFFFFF;
			line-height: 48px;
			margin-top: 37px;
		}
		.loginBtn{
			width: 68px;
			height: 68px;
			position: absolute;
			right: -35px;
			cursor:pointer;
		}
.form-box-con-title2{
	height: 34px;
	font-size: 36px;
	font-family: AlimamaShuHeiTi;
	font-weight: 400;
	color: #FFFFFF;
	line-height: 34px;
	margin-top: 24px;
}
		.data-form {
			width: 407px;
			height: 100%;
			background: #fff;
			border-radius: 10px;
			padding: 40px 20px;

			.data-form-title {
				text-align: left;
				padding-bottom: 20px;
			}

			.submit-btn {
				width: 100%;
			}

			.code-time {
				width: 120px;
				text-align: center;
				color: #17b3a3;
			}
		}

		.back-login {
			font-size: 12px;
			color: #17b3a3;
			text-align: right;
		}

		.code-form-item {
			// display: flex;
			// align-items: center;
		}

		.el-form-item {
			position: relative;

			.login-icon {
				position: absolute;
				width: 20px;
				height: 20px;
				display: inline-block;
				color: #999;
				font-size: 16px;
				top: 7px;
				left: 10px;
				z-index: 10;
			}

			.el-input__inner {
				padding-left: 40px;
			}

			.code-img {
				width: 90px;
				height: 36px;
				background: #ccc;
				vertical-align: middle;
				transform: scale(1.4);
				position: absolute;
				top: 0;
				right: 20px;
			}
		}
	}

	.role-list {
		text-align: center;
		margin-bottom: 20px;

		.el-radio__label {
			color: #fff;
		}
	}

	.router-group {
		overflow: hidden;
		padding-top: 10px;

		a {
			color: #17b3a3;
		}
	}

	.footer {
		width: 100%;
		display: flex;
		justify-content: center;
		text-align: center;
		position: fixed;
		bottom: 94px;
		left: 0;
		font-size: 14px;
		font-family: MicrosoftYaHeiLight;
		font-weight: 400;
		color: #333333;
		line-height: 30px;
	}
</style>