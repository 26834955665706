import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios';
import VueCookie from 'vue-cookie'   
import '@/icons'
import '@/utils/flexible'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import vform from '../lib/VFormDesigner.umd.min.js'
import '../lib/VFormDesigner.css'
import httpRequest from '@/utils/httpRequest'
import httpRequestAes from '@/utils/httpRequestAes'
import { JSEncrypt } from 'jsencrypt'
import '@/assets/scss/index.scss'

import GLOBAL from "./utils/global";
import VueLazyload from 'vue-lazyload';

import loading from './assets/img/login/bg.png'

// 腾讯滑块验证(新建一个js文件)
import TencentCaptcha from '@/assets/TencentCaptcha'
import * as echarts from "echarts";
Vue.use(TencentCaptcha);
// 使用
Vue.prototype.$bus = new Vue();

Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(vform)  //同时注册了v-form-designer、v-form-render等组件
Vue.use(VueCookie)

Vue.prototype.$http = httpRequest // ajax请求方法
Vue.prototype.$httpAes = httpRequestAes // ajax请求方法
Vue.prototype.$global = GLOBAL;
Vue.prototype.$echarts = echarts;
Vue.use(VueLazyload,{
    loading: loading
});
if (typeof window !== 'undefined') {
  window.axios = axios
}
const pointDoms = []; // 使用这个指令的所有DOM对象
Vue.directive('points', {
    inserted(el, binding) {
        pointDoms.push(el); // 存储使用这个指令的DOM
        el.addEventListener('click', () => {
            // 禁用所有使用这个指令的DOM结构点击事件
            pointDoms.forEach(pointItem => {
                pointItem.style.pointerEvents = 'none';
            });
            setTimeout(() => {
                // 启动所有使用这个指令的DOM结构点击事件
                pointDoms.forEach(pointItem => {
                    pointItem.style.pointerEvents = 'auto';
                });
            }, binding.value || 2000);
        });
    }
});

// 通过Vue的指令方法，定义指令名称
Vue.directive('Int', {
  // bind绑定 获取当前目标的input（就是在哪个文本框上使用指令）
  bind: function(el) {
      const input = el.getElementsByTagName('input')[0];
      if (input) {
          // 调用input方法，加入正则校验的逻辑
          input.onkeyup = function(e) {
              if (input.value.length === 1) {
                  input.value = input.value.replace(/[^0-9]/g, '');
              } else {
                  input.value = input.value.replace(/[^\d]/g, '');
              }
              // 调用自定义事件
              trigger(input, 'input');
          };
          input.onblur = function(e) {
              if (input.value.length === 1) {
                  input.value = input.value.replace(/[^0-9]/g, '');
              } else {
                  input.value = input.value.replace(/[^\d]/g, '');
              }
              trigger(input, 'input');
          };
      }
  }
});
// 创建自定义事件
const trigger = (el, type) => {
  const e = document.createEvent('HTMLEvents');
  // 初始化默认值
  e.initEvent(type, true, true);
  // 触发自定义事件
  el.dispatchEvent(e);
};
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
