<template>
  <div class="send-screening">
    <el-dialog title="写方案" :visible.sync="visible" width="500px">
      <div class="page-con">
        <el-form
          :model="dataForm"
          :rules="rules"
          ref="dataForm"
          label-width="120px"
          class="demo-dataForm"
        >
          <el-form-item label="方案名称" prop="programName">
            <el-input style="width:90%;" placeholder="请输入方案名称" v-model="dataForm.programName"></el-input>
          </el-form-item>
          <el-form-item label="方案发送人">{{loginName}}</el-form-item>
          <el-form-item label="执行开始时间" prop="executionTime">
            <el-date-picker
              style="width:90%;"
              v-model="dataForm.executionTime"
              type="datetime"
              placeholder="选择日期时间"
              value-format="yyyy-MM-dd HH:mm:ss"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="方案名称" prop="programId">
            <el-select
              v-model="dataForm.programId"
              style="width:90%;"
              placeholder="请选择方案名称"
              filterable
            >
              <el-option
                v-for="(item,index) in programNameList"
                :key="index"
                :value="item.id"
                :label="item.programName"
              >{{item.programName}}</el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="send-screen-label" :label="'发送人员筛选'+totalCount+'人'" required>
            <div class="send-staff-list">
              <el-scrollbar
                class="custom-scrollbar"
                style="height:100%;overflow:hidden;padding:20px 0 20px 20px;"
                ref="scrollbar"
              >
                <el-checkbox
                  :indeterminate="isIndeterminate"
                  v-model="checkAll"
                  @change="handleCheckAllChange"
                >全选</el-checkbox>
                <el-checkbox-group v-model="checkStaff" @change="handleCheckedChange">
                  <el-checkbox
                    v-for="(item,index) in checkStaffList"
                    :label="item.patience_id"
                    :key="index"
                    style="width:80%;"
                  >{{item.patience_name}}（{{item.patience_link_tel}}）</el-checkbox>
                </el-checkbox-group>
              </el-scrollbar>
            </div>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="clickSend">发送</el-button>
          <el-button @click="visible = false">取消</el-button>
        </span>
      </div>
    </el-dialog>

    <el-dialog title="发送确认" :visible.sync="sendConfirmDialog" width="500px">
      <div>确认发送到用户吗？用户将会收到方案</div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmSend">确认发送</el-button>
        <!-- <el-button @click="goMbyhglList">返回我的筛查用户</el-button> -->
        <!-- <el-button @click="goAnalysis">评估分析</el-button> -->
      </span>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment";
import qs from "qs";
export default {
  data() {
    return {
      visible: false,
      id: "",
      dataForm: {
        programName: "", //方案名称
        programId: "", //方案id
        patienceId: "", //患者id
        executionTime: "", //执行时间
        hospitalId: "", //医院id
        deptId: "" //科室id
      },
      loginName: "", //当前登录者姓名
      rules: {
        programName: [
          { required: true, message: "请输入方案名称", trigger: "blur" }
        ],
        executionTime: [
          {
            required: true,
            message: "请选择执行时间",
            trigger: "blur"
          }
        ],
        programId: [
          { required: true, message: "请选择方案名称", trigger: "change" }
        ]
      },
      programNameList: [], //方案名称列表
      checkAll: false,
      checkStaff: [],
      checkStaffList: [],
      totalCount: 0,
      isIndeterminate: true,
      sendConfirmDialog: false, //确认的弹窗
      flag: true //防止重复点击
    };
  },
  mounted() {
    this.dataForm.executionTime = moment(new Date()).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    if (localStorage.getItem("userInfo")) {
      this.loginName = JSON.parse(localStorage.getItem("userInfo")).name;
      this.dataForm.hospitalId = JSON.parse(
        localStorage.getItem("userInfo")
      ).deptPid;
      this.dataForm.deptId = JSON.parse(
        localStorage.getItem("userInfo")
      ).deptId;
    }
  },
  methods: {
    init(patienceIds) {
      let _this = this;
      console.log(patienceIds,'patienceIds');
      _this.visible = true;
      _this.flag = true;
      _this.$nextTick(() => {
        _this.$refs["dataForm"].resetFields();
      });
      if (patienceIds.length) {
        _this.checkStaff = patienceIds;
      } else {
        _this.checkStaff = [];
      }
      // this.dataForm.patienceId = patienceIds
      _this.getProgramNameList();
      _this.getDataList();
    },
    // 获取发送人员列表
    getDataList() {
      let that = this;
      let params = {
        intParam1: 9999,
        intParam2: 1,
        stringParam1: localStorage.currentTeam ? JSON.parse(localStorage.currentTeam).id: "",
      };
      that.dataListLoading = true;
      that
        .$httpAes({
          url: that.$httpAes.adornUrl("/teamsPatience/selectTeamsPatience"),
          method: "post",
          data: params
        })
        .then(({ data }) => {
          if (data.status) {
            that.checkStaffList = data.data;
            that.totalCount = data.totalCount;
            if (that.totalCount == that.checkStaff.length) {
              that.checkAll = true;
              that.isIndeterminate = false;
            }
          }
        });
    },
    // 获取方案名称
    getProgramNameList() {
      let _this = this;
      let unionId = "";
      if (localStorage.userInfo) {
        unionId = JSON.parse(localStorage.userInfo).doctorUnionId;
      }
      let params = {
        pageSize: 10000,
        pageNo: 1
        // unionId: unionId
      };
      _this
        .$http({
          url: _this.$http.adornUrl("/programInfo/list"+'?teamsId='+JSON.parse(localStorage.currentTeam).id),
          method: "post",
          data: params
        })
        .then(({ data }) => {
          if (data.status) {
            _this.programNameList = data.data;
          }
        });
    },
    // 点击发送
    clickSend() {
      let _this = this;
      _this.$refs["dataForm"].validate(valid => {
        if (valid) {
          _this.sendConfirmDialog = true;
        }
      });
    },
    // 确认发送
    confirmSend() {
      let _this = this;
      if (localStorage.currentTeam) {
        _this.dataForm.teamId = JSON.parse(localStorage.currentTeam).id;
        _this.dataForm.enertyId = JSON.parse(localStorage.currentTeam).entityId;
      }
      // _this.dataForm.teamId = '7b46ed791ec34b7aa078abf6f4ce4ad3'
      // _this.dataForm.patienceId = '55880a2f9f294133b73af5838abe13aa'
      // _this.dataForm.programName = 'test'
      // _this.dataForm.programId = 'c73033b050e4493a845b2872a95694ac'
      if(this.checkAll){
        this.checkStaff = this.checkStaffList.map(v=>v.patience_id)
      }
      _this.$refs["dataForm"].validate(valid => {
        if (valid) {
          if (!_this.checkStaff.length) {
            _this.$message.error("请选择发送人员");
            return;
          } else {
            _this.dataForm.patienceId = _this.checkStaff.join(",");
          }
          console.log( _this.dataForm.patienceId,' _this.dataForm.patienceId');
          if (_this.flag) {
            _this.flag = false;
            _this
              .$httpAes({
                url: _this.$httpAes.adornUrl("/patienceProgram/add"),
                method: "post",
                data: _this.dataForm
              })
              .then(({ data }) => {
                if (data.status) {
                  _this.$message({
                    message: data.msg,
                    type: "success",
                    duration: 1500,
                    onClose: () => {
                      _this.visible = false;
                      _this.sendConfirmDialog = false;
                      _this.flag = true;
                      _this.$emit("refreshDataList",1);
                    }
                  });
                } else {
                  _this.$message.error(data.msg);
                  _this.flag = true;
                }
              });
          }
        }
      });
    },
    // 返回慢病用户
    goMbyhglList() {
      let _this = this;
      _this.visible = false;
      _this.sendConfirmDialog = false;
    },
    // 返回评估分析
    goAnalysis() {
      let _this = this;
      _this.$router.push({ name: "mbyhglAnalysis" });
    },
    // 评估分析

    removeCheckbox(item) {
      this.choseList.forEach((ele, index) => {
        if (item.id == ele.id) {
          this.choseList.splice(index, 1);
        }
      });
    },
    //全选
    handleCheckAllChange(val) {
      this.checkStaff = val;
      this.isIndeterminate = false;
      this.checkAll = val

    },
    handleCheckedChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.checkStaffList.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.checkStaffList.length;
    }
  }
};
</script>

<style lang="scss" scoped>
.send-screening {
  .chose-list-box {
    overflow: hidden;
    .list-item {
      padding: 0 20px;
      border: 1px solid #dcdfe6;
      margin-left: 10px;
      border-radius: 4px;
      margin-bottom: 10px;
      float: left;
      cursor: pointer;
      &:hover {
        color: #409eff;
      }
      &.list-item2 {
        background: #409eff;
        color: #fff;
        .el-icon-delete {
          margin-left: 10px;
        }
      }
    }
  }
  .send-staff-list {
    width: 90%;
    height: 200px;
    border: 1px solid #dcdfe6;
  }
}
</style>