import request from "@/utils/request";
import qs from "qs";
export const getTeamsPatienceListApi = params => {
  return request({
    method: "get",
    url: "/teamsPatience/list",
    params
  });
};

export const postCaseAddApi = params => {
  return request({
    method: "post",
    url: "/patience/add",
    data: qs.stringify(params)
  });
};

export const postCaseTeamsAddApi = params => {
  return request({
    method: "post",
    url: "/teamsPatience/add",
    data: qs.stringify(params)
  });
};
