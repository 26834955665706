import Vue from 'vue'
import router from '@/router'
import store from '@/store'
import moment from "moment";
/**
 * 获取uuid
 */
export function getUUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    return (c === 'x' ? (Math.random() * 16 | 0) : ('r&0x3' | '0x8')).toString(16)
  })
}
export function getAge(v) {
 var birthDate = new Date(v);
      let birth = moment(birthDate).format("YYYY-MM-DD");
      // console.log(birthDate,'birthDate',birth);
      var nowDateTime = new Date();
      var age = nowDateTime.getFullYear() - birthDate.getFullYear();
      //再考虑月、天的因素;.getMonth()获取的是从0开始的，这里进行比较，不需要加1
      if (
        nowDateTime.getMonth() < birthDate.getMonth() ||
        (nowDateTime.getMonth() == birthDate.getMonth() &&
          nowDateTime.getDate() < birthDate.getDate())
      ) {
        age--;
      }
	  return age
}
// 设置echarts字体
export function myEchartsFontSize(value, n) {
  let screenWidth = document.body.clientWidth;
  const result = value * (screenWidth / 1920) * n;
  return result;
}


/**
 * 获取图片地址
 */
export function getImgUrl() {
  return 'https://aibiboat.oss-cn-shanghai.aliyuncs.com/'
}

export function broadcastUrl() {
  return 'https://broadcast.aibiboat.com/live/'
}

/**
 * 是否有权限
 * @param {*} key
 */
export function isAuth(key) {
  return JSON.parse(localStorage.getItem('buttonArr') || '[]').indexOf(key) !== -1 || false
}
/**
 * 是否有权限
 * @param {*} key
 */
export function isMenuAuth(key) {
  return JSON.parse(localStorage.getItem('menuArr') || '[]').indexOf(key) !== -1 || false
}

/**
 * 树形数据转换
 * @param {*} data
 * @param {*} id
 * @param {*} pid
 */
export function treeDataTranslate(data, id = 'id', pid = 'pid') {
  var res = []
  var temp = {}
  for (var i = 0; i < data.length; i++) {
    temp[data[i][id]] = data[i]
  }
  for (var k = 0; k < data.length; k++) {
    if (temp[data[k][pid]] && data[k][id] !== data[k][pid]) {
      if (!temp[data[k][pid]]['children']) {
        temp[data[k][pid]]['children'] = []
      }
      if (!temp[data[k][pid]]['_level']) {
        temp[data[k][pid]]['_level'] = 1
      }
      data[k]['_level'] = temp[data[k][pid]]._level + 1
      temp[data[k][pid]]['children'].push(data[k])
    } else {
      res.push(data[k])
    }
  }
  return res
}

/**
 * 菜单树形数据转换
 * @param {*} data
 * @param {*} code
 * @param {*} pcode
 */
export function menuTreeDataTranslate(list) {
  let temp = {};
  let tree = {};
  for (let i in list) {
    temp[list[i].code] = list[i];
  }
  for (let i in temp) {
    if (temp[i].pcode) {
      if (!temp[temp[i].pcode].children) {
        temp[temp[i].pcode].children = new Object();
      }
      temp[temp[i].pcode].children[temp[i].code] = temp[i];
    } else {
      tree[temp[i].code] = temp[i];
    }
  }
  return tree;
}

/**
 * 清除登录信息
 */
export function clearLoginInfo() {
  localStorage.removeItem('token');
  localStorage.removeItem('type');
  localStorage.removeItem('userInfo');
  localStorage.removeItem('dept');
  localStorage.removeItem('currentTeam');
  localStorage.removeItem('teamsManages');
  sessionStorage.removeItem('radio');
  store.commit('resetStore')
  router.options.isAddDynamicMenuRoutes = false
}


// 身份证号
export const idCardRegExp = (num) => {
  if (!num) {
    return false
  }
  num = num.toUpperCase() // 身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X。
  if (!(/(^\d{15}$)|(^\d{17}([0-9]|X)$)/.test(num))) {
    // alert('输入的身份证号长度不对，或者号码不符合规定！n15位号码应全为数字，18位号码末位可以为数字或X。');
    return false
  }
  // 校验位按照ISO 7064:1983.MOD 11-2的规定生成，X可以认为是数字10。
  // 下面分别分析出生日期和校验位
  let re
  const len = num.length
  if (len === 15) {
    re = new RegExp(/^(\d{6})(\d{2})(\d{2})(\d{2})(\d{3})$/)
    const arrSplit = num.match(re) // 检查生日日期是否正确
    const dtmBirth = new Date('19' + arrSplit[2] + '/' + arrSplit[3] + '/' + arrSplit[4])
    const bGoodDay = (dtmBirth.getYear() === Number(arrSplit[2])) && ((dtmBirth.getMonth() + 1) === Number(arrSplit[3])) && (dtmBirth.getDate() === Number(arrSplit[4]))
    if (!bGoodDay) {
      // alert('输入的身份证号里出生日期不对！');
      return false
    } else {
      // 将15位身份证转成18位//校验位按照ISO 7064:1983.MOD 11-2的规定生成，X可以认为是数字10。
      const arrInt = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]
      const arrCh = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2']
      let nTemp = 0
      num = num.substr(0, 6) + '19' + num.substr(6, num.length - 6)
      for (let i = 0; i < 17; i++) {
        nTemp += num.substr(i, 1) * arrInt[i]
      }
      num += arrCh[nTemp % 11]
      return true
    }
  }
  if (len === 18) {
    re = new RegExp(/^(\d{6})(\d{4})(\d{2})(\d{2})(\d{3})([0-9]|X)$/)
    const arrSplit = num.match(re) // 检查生日日期是否正确
    const dtmBirth = new Date(arrSplit[2] + '/' + arrSplit[3] + '/' + arrSplit[4])
    const bGoodDay = (dtmBirth.getFullYear() === Number(arrSplit[2])) && ((dtmBirth.getMonth() + 1) === Number(arrSplit[3])) && (dtmBirth.getDate() === Number(arrSplit[4]))
    if (!bGoodDay) {
      console.log(111)
      // alert('输入的身份证号里出生日期不对！');
      return false
    } else {
      // 检验18位身份证的校验码是否正确。
      // 校验位按照ISO 7064:1983.MOD 11-2的规定生成，X可以认为是数字10。
      const arrInt = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]
      const arrCh = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2']
      let nTemp = 0
      for (let i = 0; i < 17; i++) {
        nTemp += num.substr(i, 1) * arrInt[i]
      }
      const valnum = arrCh[nTemp % 11]

      if (valnum !== num.substr(17, 1)) {
        // alert('18位身份证的校验码不正确！应该为：'+valnum);
        console.log('18位身份证的校验码不正确！应该为：' + valnum)
        return false
      }
      return true
    }
  }
  return false
}

// 鼠标移出时根据身份证号显示对应的性别和出生日期
export const idCardFunction = (IdCard, type) => {
  if (type === 1) {
    //获取出生日期
    let birthday =
      IdCard.substring(6, 10) +
      "-" +
      IdCard.substring(10, 12) +
      "-" +
      IdCard.substring(12, 14);
    return birthday;
  }
  if (type === 2) {
    //获取性别
    if (parseInt(IdCard.substr(16, 1)) % 2 === 1) {
      return "男";
    } else {
      return "女";
    }
  }
  if (type === 3) {
    //获取年龄
    var ageDate = new Date();
    var month = ageDate.getMonth() + 1;
    var day = ageDate.getDate();
    var age = ageDate.getFullYear() - IdCard.substring(6, 10) - 1;
    if (
      IdCard.substring(10, 12) < month ||
      (IdCard.substring(10, 12) === month &&
        IdCard.substring(12, 14) <= day)
    ) {
      age++;
    }
    if (age <= 0) {
      age = 1;
    }
    return age;
  }
}
