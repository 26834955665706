<template>
  <el-form
    :model="dataForm"
    :rules="rules"
    ref="dataForm"
    label-width="100px"
    class="case-form"
  >
    <div class="top">
      <el-form-item label="病例ID" prop="caseId">
        <el-input v-model="dataForm.caseId" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="姓名" prop="patienceName">
        <el-input
          v-model="dataForm.patienceName"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item label="证件类型" prop="paperType">
        <el-select
          v-model="dataForm.paperType"
          placeholder="请选择"
          style="width: 350px"
        >
          <el-option
            v-for="item in zjlx"
            :label="item.name"
            :value="item.value"
          
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="证件号" prop="patienceIdCard">
        <el-input
          v-model="dataForm.patienceIdCard"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item v-show="labelFlag" label="性别" prop="patienceSex">
        <el-radio-group v-model="dataForm.patienceSex">
          <el-radio label="1">男</el-radio>
          <el-radio label="2">女</el-radio>
        </el-radio-group>
        <!-- {{ dataForm.patienceSexName }} -->
      </el-form-item>
      <el-form-item v-show="labelFlag" label="出生日期" prop="patienceBirth">
        <!-- <el-input v-model="dataForm.patienceBirth"></el-input> -->
        <!--   {{ dataForm.patienceBirth }} -->
        <el-date-picker
          v-model="dataForm.patienceBirth"
          type="datetime"
          placeholder="选择日期"
          value-format="yyyy-MM-dd"
          format="yyyy-MM-dd"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="主要诊断" prop="mainDiagnosis">
        <el-select
          v-model="dataForm.mainDiagnosis"
          filterable
          placeholder="请选择"
          style="width: 350px"
          :filter-method="getPorjType"
        >
          <el-option
            v-for="item in typeArr"
            :label="item.name"
            :value="item.value"
          
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="其他诊断" prop="otherDiagnosis">
        <el-select
          v-model="otherDiagnosis"
          filterable
          multiple
          placeholder="请选择"
          style="width: 350px"
          :filter-method="getPorjType"
        >
          <el-option
            v-for="item in typeArr"
            :label="item.name"
            :value="item.value"
           
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="联系方式" prop="patienceLinkTel">
        <el-input
          v-model="dataForm.patienceLinkTel"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item label="家属联系方式" prop="dependentsContact">
        <el-input
          v-model="dataForm.dependentsContact"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item label="责任医生">
        <el-select
          v-model="dataForm.responsibleDoctor"
          filterable
          placeholder="请选择"
          style="width: 350px"
        >
          <el-option
            v-for="item in teamsMemberList"
            :label="item.docterName"
            :value="item.docterId"
         
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="首诊时间">
        <!-- 	<el-input v-model="dataForm.createTime"></el-input> -->
        <el-date-picker
          v-model="dataForm.createTime"
          type="datetime"
          placeholder="选择日期时间"
          value-format="yyyy-MM-dd"
          format="yyyy-MM-dd"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="居住地区" prop="resource">
        <!-- <select-area v-model="cityInfo" :myData="cityInfo"></select-area> -->
        <city-multi-cascader
          v-model="cityInfo"
          :myData="cityInfo"
          style="width: 350px"
        ></city-multi-cascader>
      </el-form-item>
      <el-form-item label="详细地址">
        <el-input
          v-model="dataForm.addressDetail"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <el-form-item label="籍贯" prop="resource">
        <!-- <select-area v-model="cityInfo" :myData="cityInfo"></select-area> -->
        <city-multi-cascader
          v-model="nativePlaceInfo"
          :myData="nativePlaceInfo"
          style="width: 350px"
        ></city-multi-cascader>
      </el-form-item>
      <el-form-item label="备注" prop="mbglContent">
        <el-input
          v-model="dataForm.mbglContent"
          placeholder="请输入"
        ></el-input>
      </el-form-item>
      <!-- <el-form-item label="家属联系方式2">
          <el-input
            v-model="dataForm.dependentsContact2"
            placeholder="请输入"
          ></el-input>
        </el-form-item> -->
    </div>
    <div class="bottom">
      <el-form-item label="" class="el-form-item-nolable">
        <el-button type="primary" @click="submitForm">保存</el-button>
        <el-button @click="submitForm(1)">保存并进入病例详情</el-button>
      </el-form-item>
    </div>
  </el-form>
  <!-- <div class="foot"></div> -->
</template>
<script>
var regPhone =
  /^(13[0-9]|14[14-8]|15[0-35-9]|16[6]|17[0-8]|18[0-9]|19[0-9])[0-9]{8}$/;
var regIdCard = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
import qs from "qs";
import moment from "moment";
import { postCaseAddApi, postCaseTeamsAddApi } from "@/api/case";
import CityMultiCascader from "@/components/cascader/cityCascader";
import { idCardRegExp, idCardFunction } from "@/utils";

import pinyin from "pinyin-match";
export default {
  components: {
    CityMultiCascader,
  },
  data() {
    var validateIdCard = (rule, value, callback) => {
		
      if (this.dataForm.paperType == 1) {
        if (value === "") {
          callback(new Error("请输入身份证号"));
          this.labelFlag = false;
        } else if (!regIdCard.test(value)) {
          callback(new Error("身份证格式不正确"));
          this.labelFlag = false;
        } else {
			
          this.dataForm.patienceBirth = idCardFunction(value, 1);
		
          this.dataForm.patienceSexName = idCardFunction(value, 2);
          if (this.dataForm.patienceSexName == "男") {
            this.dataForm.patienceSex = "1";
          } else {
            this.dataForm.patienceSex = "2";
          }
          if (!this.id) {
            this.idCardIsExist(value);
          }
          this.labelFlag = true;
          callback();
        }
      } else {
        if (value === "") {
          callback(new Error("请输入证件号"));
          this.labelFlag = false;
        } else {
          this.labelFlag = true;
          callback();
        }
      }
    };
    var validatePhone = (rule, value, callback) => {
      if (value === "") {
        // callback(new Error("请输入联系方式"));
      } else if (!regPhone.test(value)) {
        callback(new Error("联系方式格式不正确"));
      } else {
        callback();
      }
    };
    return {
      otherDiagnosis: [],
      teamsMemberList: [],
      zjlx: [],
      typeArr: [],
      cityInfo: {}, //选择省市区
      flag: true, //防止重复提交
      labelFlag: false,
      dataForm: {
        mbglContent: "",
        dependentsContact: "",
        dependentsContact2: "",
        responsibleDoctor: "", //责任医生
        familyLinkTel: "", //家属联系电话
        patienceIdCard: "", //身份证号
        patienceSex: "", //性别
        patienceName: "", //姓名

        patienceAge: "", //年龄
        patienceLinkTel: "", //联系方式
        mbglResourceType: "", //来源
        patienceBirth: "",
        createTime: "",
        mbglReasonType: [], //原因
        delivery: false,
        type: [],
        resource: "",
        addressDetail: "",
        desc: "",
        region: "",
        paperType: "",
        otherPaper: "",
        otherDiagnosis: "",
        mainDiagnosis: "",
      },
      rules: {
        patienceIdCard: [
          {
            required: true,
            validator: validateIdCard,
            trigger: "blur",
          },
        ],
        patienceBirth: [
          {
            required: true,
            message: "请输入出生日期",
            trigger: "blur",
          },
        ],

        patienceName: [
          {
            required: true,
            message: "请输入姓名",
            trigger: "blur",
          },
        ],

        paperType: [
          {
            required: true,
            message: "请选择证件类型",
            trigger: "blur",
          },
        ],

        mainDiagnosis: [
          {
            required: true,
            message: "请选择主要诊断",
            trigger: "blur",
          },
        ],
        // dependentsContact: [
        //   {
        //     required: true,
        //     message: "请输入家庭联系方式",
        //     trigger: "blur",
        //   },
        // ],
        patienceLinkTel: [
          {
            required: false,
            // validator: validatePhone,
            trigger: "blur",
          },
        ],
      },
      nativePlaceInfo: {}, //籍贯
      timer: null,
      curList: [],
    };
  },
  // watch: {
  //   "dataForm.patienceIdCard": function(v) {
  //     console.log(this.dataForm.paperType);
  //     if (this.dataForm.paperType == 3) {
  //       // console.log(v,'watch');
  //       var len = (v + "").length;
  //       // console.log(len);
  //       var strBirthday = "";
  //       let genderCode;
  //       if (len == 18) {
  //         //处理18位的身份证号码从号码中得到生日和性别代码
  //         strBirthday =
  //           v.substr(6, 4) + "/" + v.substr(10, 2) + "/" + v.substr(12, 2);
  //         genderCode = v.charAt(16);
  //       }
  //       if (len == 15) {
  //         strBirthday =
  //           "19" +
  //           v.substr(6, 2) +
  //           "/" +
  //           v.substr(8, 2) +
  //           "/" +
  //           v.substr(10, 2);
  //         genderCode = v.charAt(14);
  //       }
  //       if (genderCode && !isNaN(genderCode)) {
  //         // 两代身份证号码的性别代码都为男奇女偶
  //         if (parseInt(genderCode) % 2 == 0) {
  //           this.dataForm.patienceSex = "2";
  //         } else {
  //           this.dataForm.patienceSex = "1";
  //         }
  //       }

  //       //时间字符串里，必须是“/”
  //       var birthDate = new Date(strBirthday);
  //       let birth = moment(birthDate).format("YYYY-MM-DD");
  //       // console.log(birthDate,'birthDate',birth);
  //       var nowDateTime = new Date();
  //       var age = nowDateTime.getFullYear() - birthDate.getFullYear();
  //       //再考虑月、天的因素;.getMonth()获取的是从0开始的，这里进行比较，不需要加1
  //       if (
  //         nowDateTime.getMonth() < birthDate.getMonth() ||
  //         (nowDateTime.getMonth() == birthDate.getMonth() &&
  //           nowDateTime.getDate() < birthDate.getDate())
  //       ) {
  //         age--;
  //       }
  //       // console.log(age,'age');
  //       if (age) {
  //         // console.log(this.dataForm,'dataForm');
  //         this.dataForm.patienceAge = age;
  //         this.dataForm.patienceBirth = birth;
  //         this.ageFlag = true;
  //       }
  //     }
  //   },
  //   "dataForm.patienceBirth": function(v) {
  //     //时间字符串里，必须是“/”
  //     var birthDate = new Date(v);
  //     let birth = moment(birthDate).format("YYYY-MM-DD");
  //     // console.log(birthDate,'birthDate',birth);
  //     var nowDateTime = new Date();
  //     var age = nowDateTime.getFullYear() - birthDate.getFullYear();
  //     //再考虑月、天的因素;.getMonth()获取的是从0开始的，这里进行比较，不需要加1
  //     if (
  //       nowDateTime.getMonth() < birthDate.getMonth() ||
  //       (nowDateTime.getMonth() == birthDate.getMonth() &&
  //         nowDateTime.getDate() < birthDate.getDate())
  //     ) {
  //       age--;
  //     }
  //     console.log(age);
  //     // console.log(age,'age');
  //     if (age) {
  //       // console.log(this.dataForm,'dataForm');
  //       this.dataForm.patienceAge = age;

  //       this.ageFlag = true;
  //     }
  //   },
  // },
  mounted() {
    let _this = this;
    _this.getTeamsMember();
    _this.getSourceList();
    _this.getPorjType();
    this.dataForm.responsibleDoctor = JSON.parse(localStorage.userInfo).id;
  },
  methods: {
    //先一个试试
    async handleClick() {
      console.log("表单数据", this.dataForm);
      const res = await postCaseTeamsAddApi(this.dataForm);
      console.log(res);
      if (res.status) {
        this.$message.success(res.status);
        // this.$router.push("/case");
      } else {
        this.$message.error(res.status);
      }
      // this.$router.push("visit/details");
    },

    // 提交个人信息
    submitForm(val) {
      let _this = this;
      let params = {};
      if (_this.id) {
        params = _this.info;
      }
      if (_this.dataForm.patienceId) {
        params.id = _this.dataForm.patienceId;
      }

      params.patienceIdCard = _this.dataForm.patienceIdCard;
      params.mainDiagnosis = _this.dataForm.mainDiagnosis;
      params.otherDiagnosis = _this.otherDiagnosis.join(",");

      params.paperType = _this.dataForm.paperType;
      params.patienceName = _this.dataForm.patienceName;
      params.patienceAge = _this.dataForm.patienceAge;
      params.patienceLinkTel = _this.dataForm.patienceLinkTel;
      params.patienceSex = _this.dataForm.patienceSex;
      params.patienceBirth = _this.dataForm.patienceBirth;
      params.dependentsContact = _this.dataForm.dependentsContact;
      params.dependentsContact2 = _this.dataForm.dependentsContact2;
      params.addressDetail = _this.dataForm.addressDetail;

      if (_this.cityInfo) {
        params.patientProvince = _this.cityInfo.province;
        params.provinceName = _this.cityInfo.provinceName;
        params.patientCity = _this.cityInfo.city;
        params.cityName = _this.cityInfo.cityName;
        params.patientCounty = _this.cityInfo.block;
        params.countyName = _this.cityInfo.blockName;
      }
      if (_this.nativePlaceInfo && _this.nativePlaceInfo.province) {
        params.nativePlace = JSON.stringify(_this.nativePlaceInfo);
      } else {
        params.nativePlace = "";
      }
      console.log("123", params);
      // return;
      _this.$refs["dataForm"].validate((valid) => {
        console.log("123", valid);
        if (valid) {
          if (this.dataForm.patienceLinkTel) {
            if (!regPhone.test(this.dataForm.patienceLinkTel)) {
              this.$message.error("联系方式格式不正确");
            }
          }
          if (_this.flag) {
            _this.flag = false;
            // return;
            _this
              .$httpAes({
                url: _this.$httpAes.adornUrl(
                  params.id ? "/patience/update" : "/patience/add"
                ),
                method: "post",
                data: params,
              })
              .then(({ data }) => {
                if (data.status) {
                  _this.submitPatienceInfo(data.data.id, val, data.data);
                } else {
                  _this.$message.error(data.msg);
                  _this.flag = true;
                }
              });
          }
        }
      });
    },

    getPorjType(ename) {
      this.dataListLoading = true;
      this.$httpAes({
        url: this.$httpAes.adornUrl("/dict/list?type=主要诊断"),
        method: "post",
        data: {
			stringParam1:'主要诊断',
          stringParam2: ename?ename:""
        },
      }).then(({ data }) => {
        this.typeArr = data.data;
      });
    },
    init(rows) {
      console.log(rows);
      let _this = this;
      _this.ageFlag = false;
      _this.flag = true;
      _this.visible = true;
      _this.id = "";
      _this.cityInfo = {};
      _this.dataForm.mbglResourceType = "";
      _this.dataForm.mbglReasonType = "";
      _this.dataForm = {
        patienceIdCard: "", //身份证号
        patienceName: "", //姓名
        patienceSex: "1", //性别
        patienceAge: "", //年龄
        patienceBirth: "", //年龄
        patienceLinkTel: "", //联系方式
        mbglResourceType: "", //来源
        mbglReasonType: [], //原因
        mbglDescription: "",
        mbglContent: "",
        content: "", //病情备注
      };
      _this.id = "";
      _this.$nextTick(() => {
        _this.$refs["dataForm"].resetFields();
      });
      if (rows.id) {
        _this.id = rows.id;
        _this.getInfo();
      } else {
      }
      _this.getSourceList();
      _this.getReasonList();
    },

    //根据身份证显示已存在患者信息
    idCardIsExist() {
      let _this = this;
      let params = {
        stringParam1: _this.dataForm.patienceIdCard,
      };
      _this
        .$httpAes({
          url: _this.$httpAes.adornUrl("/patience/list"),
          method: "post",
          data: params,
        })
        .then(({ data }) => {
          if (data.status && data.data.length) {
            let res = data.data[0];
            _this.dataForm.patienceId = res.id;
            _this.dataForm.patienceIdCard = res.patienceIdCard;
            _this.dataForm.patienceName = res.patienceName;
            _this.dataForm.patienceAge = _this.dataForm.patienceAge
              ? _this.dataForm.patienceAge
              : res.patienceAge;
            _this.dataForm.patienceBirth = _this.dataForm.patienceBirth
              ? _this.dataForm.patienceBirth
              : res.patienceBirth;
            // _this.dataForm.patienceAge = res.patienceAge;
            _this.dataForm.patienceLinkTel = res.patienceLinkTel;
            _this.cityInfo = {
              sheng: res.patientProvince,
              shengName: res.provinceName,
              shi: res.patientCity,
              shiName: res.cityName,
              qu: res.patientCounty,
              quName: res.countyName,
            };
            _this.dataForm.patienceSex = res.patienceSex.toString();
          } else {
            _this.dataForm.patienceId = "";
            _this.cityInfo = {};
          }
        });
    },
    // 获取来源列表
    getSourceList() {
      let _this = this;
      _this
        .$httpAes({
          url: _this.$httpAes.adornUrl("/dict/list?type=证件类型"),
          method: "post",
		  data: {
		  	stringParam1:'证件类型'
		  },
        })
        .then(({ data }) => {
          _this.zjlx = data.data;
        });
    },
    getTeamsMember() {
      let _this = this;
      let params = {
        intParam2: 1,
        intParam1: 9999,
        stringParam6: 1,
        stringParam5: JSON.parse(localStorage.currentTeam).id,
      };

      _this
        .$httpAes({
          url: _this.$httpAes.adornUrl("/teamsMember/list"),
          method: "post",
          data: params,
        })
        .then(({ data }) => {
			console.log('/teamsMember/list')
			console.log(params)
				console.log(data.data)
          _this.teamsMemberList = data.data;
        });
    },
    // 获取来源列表
    getReasonList() {
      let _this = this;
      _this
        .$httpAes({
          url: _this.$httpAes.adornUharl("/dict/list?type=筛查原因"),
          method: "post",
		  data: {
		  	stringParam1:'筛查原因'
		  },
        })
        .then(({ data }) => {
          _this.reasonList = data.data;
        });
    },

    // 提交病患信息
    submitPatienceInfo(patienceId, val, form) {
      let _this = this;
      let teamsId = "";
      let enertyId = "";
      let hospitalId = "";
      if (localStorage.currentTeam) {
        teamsId = JSON.parse(localStorage.currentTeam).id;
        enertyId = JSON.parse(localStorage.currentTeam).entityId;
      }
      if (localStorage.userInfo) {
        hospitalId = JSON.parse(localStorage.userInfo).deptPid;
      }
      let params = {
        teamsId: teamsId,
        enertyId: enertyId,
        responsibleDoctor: _this.dataForm.responsibleDoctor,
        hospitalId: hospitalId,
        patienceId: patienceId,
        mbglResourceType: _this.dataForm.mbglResourceType,
        mbglDescription: _this.dataForm.mbglDescription,
        mbglContent: _this.dataForm.mbglContent,
        content: _this.dataForm.content,
        type: 2,
        caseId: _this.dataForm.caseId,
      };
      params.mainDiagnosis = _this.dataForm.mainDiagnosis;
      params.otherDiagnosis = _this.otherDiagnosis.join(",");
      if (
        _this.dataForm.mbglReasonType &&
        _this.dataForm.mbglReasonType.length
      ) {
        params.mbglReasonType = _this.dataForm.mbglReasonType.join(",");
      } else {
        params.mbglReasonType = "";
      }
      if (_this.id) {
        params = this.info2;
        params.mbglResourceType = _this.dataForm.mbglResourceType;
        params.mbglDescription = _this.dataForm.mbglDescription;
        params.mbglContent = _this.dataForm.mbglContent;
        params.content = _this.dataForm.content;
        if (
          _this.dataForm.mbglReasonType &&
          _this.dataForm.mbglReasonType.length
        ) {
          params.mbglReasonType = _this.dataForm.mbglReasonType.join(",");
        } else {
          params.mbglReasonType = "";
        }
        this.$delete(params, "followUps");
        this.$delete(params, "patience");
        this.$delete(params, "patiencePrograms");
        this.$delete(params, "sysPics");
        params.id = _this.id;
      }
      _this
        .$httpAes({
          url: _this.$httpAes.adornUrl("/teamsPatience/addTeamsPatience"),
          method: "post",
          data: params,
        })
        .then(({ data }) => {
          if (data.status) {
            _this.$message({
              message: data.msg,
              type: "success",
              duration: 1500,
              onClose: () => {
                _this.visible = false;
                _this.flag = true;
                if (val != undefined && val == 1) {
                  if (form.patienceSex == 1) {
                    form.patienceSexName = "男";
                  } else {
                    form.patienceSexName = "女";
                  }
                  let obj = {
                    patience_sex: form.patienceSex,
                    patience_name: form.patienceName,
                    update_time: form.updateTime,
                    patience_age: form.patienceAge,
                    patience_birth: form.patienceBirth,
                    patienceSexName: form.patienceSexName,
                    id: data.data.id,
                    patience_id: patienceId,
                  };
                  localStorage.patientInfo = JSON.stringify(obj);
                  this.$router.push({
                    name: "case-details",
                    params: obj,
                  });
                } else {
                  this.$router.go(-1);
                }
              },
            });
          } else {
            _this.$message.error(data.msg);
            _this.flag = true;
          }
        });
    },
    // 模糊查询逻辑 加入了 timer 防抖
    pinyingMatch(value) {
      if (this.timer) clearTimeout(this.timer);

      this.timer = setTimeout(() => {
        // 有值时才执行过滤

        if (value) {
          //搜索到相应的数据进行显示
          this.curList = this.typeArr.filter((item) =>
            pinyin.match(item.name, value)
          );
        } else {
          this.curList = this.typeArr;
        }
      }, 500);
    },

    visibleChange(val) {
      if (val) {
        this.curList = this.typeArr;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.case-form {
  .el-input {
    width: 350px;
  }
  .el-select {
    width: 350px !important;
    .el-select__tags {
      height: 36px !important;
      .el-input--suffix .el-input__inner {
        height: 36px !important;
      }
    }
  }
  .el-cascader {
    width: 350px !important;
  }
  .top {
    overflow: hidden;
    .el-form-item {
      width: 50%;
      float: left;
    }
  }

  .bottom {
    width: 100%;
    text-align: center;

    .el-button {
      width: 74px;
      height: 36px;
      background: #0099ff;
      border-radius: 2px;
    }

    .el-button:nth-of-type(2) {
      width: 172px;
      height: 36px;
      background: #eef8ff;
      border-radius: 2px;
      border: 1px solid #009cff;
      color: #009cff;
    }
  }
}
</style>
