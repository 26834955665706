// export default {
//   namespaced: true,
//   state: {
//     // 页面文档可视高度(随窗口改变大小)
//     documentClientHeight: 0,
//     // 导航条, 布局风格, defalut(默认) / inverse(反向)
//     navbarLayoutType: 'default',
//     // 侧边栏, 布局皮肤, light(浅色) / dark(黑色)
//     sidebarLayoutSkin: 'dark',
//     // 侧边栏, 折叠状态
//     sidebarFold: false,
//     // 侧边栏, 菜单
//     menuList: [],
//     menuActiveName: '',
//     // 内容, 是否需要刷新
//     contentIsNeedRefresh: false,
//     // 主入口标签页
//     mainTabs: [],
//     mainTabsActiveName: ''
//   },
//   mutations: {
//     updateDocumentClientHeight (state, height) {
//       state.documentClientHeight = height
//     },
//     updateNavbarLayoutType (state, type) {
//       state.navbarLayoutType = type
//     },
//     updateSidebarLayoutSkin (state, skin) {
//       state.sidebarLayoutSkin = skin
//     },
//     updateSidebarFold (state, fold) {
//       state.sidebarFold = fold
//     },
//     updateMenuList (state, list) {
//       state.menuList = list
//     },
//     updateMenuActiveName (state, name) {
//       state.menuActiveName = name
//     },
//     updateContentIsNeedRefresh (state, status) {
//       state.contentIsNeedRefresh = status
//     },
//     updateMainTabs (state, tabs) {
//       state.mainTabs = tabs
//     },
//     updateMainTabsActiveName (state, name) {
//       state.mainTabsActiveName = name
//     }
//   }
// }
// 
export default {
	namespaced: true,
	state: {
		token: '',
		// 页面文档可视高度(随窗口改变大小)
		documentClientHeight: 0,
		// 导航条, 布局风格, defalut(默认) / inverse(反向)
		navbarLayoutType: 'default',
		// 侧边栏, 布局皮肤, light(浅色) / dark(黑色)
		sidebarLayoutSkin: 'dark',
		// 侧边栏, 折叠状态
		sidebarFold: false,

		roleType: 3, //默认角色
		// 侧边栏, 菜单
		menuList: [],
		menuActiveName: '',
		parentMenuActiveName: "",
		menuIndex: null,//获取点击的二级菜单的父级下表
		// 内容, 是否需要刷新
		contentIsNeedRefresh: false,
		// 主入口标签页
		mainTabs: [],
		mainTabsActiveName: '',
		addRoleFlag: false,
		roleInfoFlag: false,
		roleEditFlag: false,
		accountInfoFlag: false,
		pwdChangeFlag: false,
		roleInfo: '',
		deptList: '',
		roleList: '',
		characteristicOrderCount: 0,
		companyInfoCount: 0,
		equipmentOrderCount: 0,
		parkSuggestionsCount: 0,
		propertyRepairCount: 0,
		userCount: 0,
		total: 0,
		roleRadio:'2',
		imageUrl: '',
		teamsManages: [],//团队列表
		currentTeam: {},//目前切换的团队
		
	},
	mutations: {
		updateToken (state, val) {
			state.token = val;
		},
		updateRoleRadio (state, val) {
			state.roleRadio = val;
		},
		
		updateRoleType (state, val) {
			state.roleType = val;
		},
		updateImageUrl (state, val) {
			state.imageUrl = val;
		},
		updatePropertyRepairCount(state, val) {
			state.propertyRepairCount = val
		},
		updateCharacteristicOrderCount(state, val) {
			state.characteristicOrderCount = val
		},
		updateCompanyInfoCount(state, val) {
			state.companyInfoCount = val
		},
		updateEquipmentOrderCount(state, val) {
			state.equipmentOrderCount = val
		},
		updateParkSuggestionsCount(state, val) {
			state.parkSuggestionsCount = val
		},
		updateUserCount(state, val) {
			state.userCount = val
		},
		updateTotal(state, val) {
			state.total = val
		},
		updateDeptList(state, val) {
			state.deptList = val
		},
		updateRoleList(state, val) {
			state.roleList = val
		},
		updateDocumentClientHeight(state, height) {
			state.documentClientHeight = height
		},
		updateAddRoleFlag(state, val) {
			state.addRoleFlag = val
		},
		updateRoleInfoFlag(state, val) {
			state.roleInfoFlag = val
		},
		updateRoleEditFlag(state, val) {
			state.roleEditFlag = val
		},
		updateAccountInfoFlag(state, val) {
			state.accountInfoFlag = val
		},
		updatePwdChangeFlag(state, val) {
			state.pwdChangeFlag = val
		},
		updateRoleInfo(state, val) {
			state.roleInfo = val
		},
		updateNavbarLayoutType(state, type) {
			state.navbarLayoutType = type
		},
		updateSidebarLayoutSkin(state, skin) {
			state.sidebarLayoutSkin = skin
		},
		updateSidebarFold(state, fold) {
			state.sidebarFold = fold
		},
		updateRole(state, val) {
			state.role = val
		},
		updateMenuList(state, list) {
			state.menuList = list
		},
		updateMenuActiveName(state, name) {
			state.menuActiveName = name
		},
		updateParentMenuActiveName(state, name) {
			state.parentMenuActiveName = name
		},
		updateMenuIndex (state, name) {
			state.menuIndex = name
		},
		updateContentIsNeedRefresh(state, status) {
			state.contentIsNeedRefresh = status
		},
		updateMainTabs(state, tabs) {
			state.mainTabs = tabs
		},
		updateMainTabsActiveName(state, name) {
			state.mainTabsActiveName = name
		},
		updateTeamsManages (state, val) {
			state.teamsManages = val;
		},
		updateCurrentTeam (state, val) {
			state.currentTeam = val;
		},
	}
}
