<template>
  <div class="linkage" style="width: 100%;">
    <el-select v-model="sheng" placeholder="请选择省" @clear="clearSheng" clearable>
      <el-option v-for="item in province" :key="item.id" :label="item.name" :value="item.code"></el-option>
    </el-select>
    <el-select v-model="shi" placeholder="请选择市" @clear="clearShi" clearable>
      <el-option v-for="item in city" :key="item.id" :label="item.name" :value="item.code"></el-option>
    </el-select>
    <el-select v-model="qu" placeholder="请选择区" @clear="clearQu" clearable>
      <el-option v-for="item in block" :key="item.id" :label="item.name" :value="item.code"></el-option>
    </el-select>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: {
    visible: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      province: "",
      sheng: "",
      shengName: "",
      shi: "",
      shiName: "",
      shi1: [],
      qu: "",
      quName: "",
      qu1: [],
      city: "",
      block: ""
    };
  },
  props: {
    myData: {}
  },
  computed: {
    info() {
      return {
        sheng: this.sheng,
        shengName: this.shengName,
        shi: this.shi,
        shiName: this.shiName,
        qu: this.qu,
        quName: this.quName
      };
    }
  },
  created() {},
  mounted() {
    this.getCityData();
  },
  methods: {
    // 加载china地点数据，三级
    getCityData(type) {
      let that = this;
      let params = {
        pageNo: 1,
        pageSize: 10000
      };
      if (type == "1") {
        params.province = this.sheng;
      }
      if (type == "2") {
        params.city = this.shi;
      }
      this.$http({
        url: this.$http.adornUrl("/sysArea/getInfos"),
        method: "get",
        params: params
      })
        .then(({ data }) => {
          if (data.status) {
            if (type == 1) {
              this.city = data.data;
            } else if (type == 2) {
              this.block = data.data;
            } else {
              this.province = data.data;
            }
          }
        })
        .catch(function(error) {
          console.log(typeof +error);
        });
    },
    clearSheng() {
      this.sheng = "";
      this.shi = "";
      this.city = [];
      this.qu = "";
      this.block = [];
      this.$nextTick(() => {
        this.$emit("input", this.info);
      });
    },
    clearShi() {
      this.shi = "";
      this.qu = "";
      this.block = [];
      this.$nextTick(() => {
        this.$emit("input", this.info);
      });
    },
    clearQu() {
      this.qu = "";
      this.$nextTick(() => {
        this.$emit("input", this.info);
      });
    }
  },

  watch: {
    sheng(val) {
      if (val) {
        this.getCityData(1);
        if (this.province.length) {
          this.province.forEach(chr => {
            if (chr.code == val) {
              this.shengName = chr.name;
            }
          });
        }
        this.$nextTick(() => {
          this.$emit("input", this.info);
        });
      }
    },
    shi(val) {
      if (val) {
        this.getCityData(2);
        if (this.city.length) {
          this.city.forEach(chr => {
            if (chr.code == val) {
              this.shiName = chr.name;
            }
          });
        }
        this.$nextTick(() => {
          this.$emit("input", this.info);
        });
      }
    },
    qu(val) {
      if (val) {
        if (this.block.length) {
          this.block.forEach(chr => {
            if (chr.code == val) {
              this.quName = chr.name;
            }
          });
        }
        this.$nextTick(() => {
          this.$emit("input", this.info);
        });
      }
    },
    myData(val) {
      this.sheng = val.sheng;
      this.shengName = val.shengName;
      this.shi = val.shi;
      this.shiName = val.shiName;
      this.qu = val.qu;
      this.quName = val.quName;
    }
  }
};
</script>

<style scoped>
.linkage .el-select {
  width: 22% !important;
}
</style>
