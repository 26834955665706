<template>
  <div>
    <el-dialog title="筛查结果" :visible.sync="visible" width="1200px" :close-on-click-modal="false">
      <div class="search-list">
        <el-button type="primary" @click="confirmed" :disabled="!dataListSelections.length">批量康复</el-button>
        <el-button
          type="primary"
          @click="showMarkTag(1)"
          :disabled="!dataListSelections.length"
        >批量打标签</el-button>
        <!-- <el-button type="danger" @click="deleteHandle" :disabled="!dataListSelections.length">批量删除</el-button> -->
      </div>
      <el-table
        :data="dataList"
        v-loading="dataListLoading"
        @selection-change="selectionChangeHandle"
        style="width: 100%"
      >
        <el-table-column type="selection" width="50"></el-table-column>
        <el-table-column label="姓名" prop="patience_name" width="100"></el-table-column>
        <el-table-column label="联系方式" prop="patience_link_tel"></el-table-column>
        <el-table-column label="性别" width="80">
          <template slot-scope="scope">{{scope.row.patience_sex == 1 ? '男':'女'}}</template>
        </el-table-column>
        <el-table-column label="身份证号" prop="patience_id_card"></el-table-column>
        <el-table-column label="创建时间" prop="create_time"></el-table-column>
        <el-table-column label="年龄" prop="patience_age" width="80">
          <template
            slot-scope="scope"
          >{{(scope.row.patience_age == null || scope.row.patience_age =="") ? scope.row.patience_age:'-'}}</template>
        </el-table-column>
        <el-table-column fixed="right" width="300" label="操作">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="showMarkTag(2,scope.row)">打标签</el-button>
            <el-button type="text" size="small" @click="showAddOrUpdate(scope.row)">编辑</el-button>
            <el-button type="text" size="small" @click="showSendScreening(2,scope.row)">随访筛查</el-button>
            <el-button type="text" size="small" @click="showSendPackage(2,scope.row)">发方案</el-button>
            <el-button type="text" size="small" @click="handleRowInfo(scope.row)">详情</el-button>
            <!-- <el-button type="text" size="small" @click="deleteHandle(scope.row)" style="color:#f56c6c;">删除</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        :total="totalPage"
        layout="total, sizes, prev, pager, next, jumper"
      ></el-pagination>
    </el-dialog>
    <add-or-update v-if="addOrUpdateDialog" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
    <!-- 打标签 -->
    <mark-tag
      v-if="markTagDialog"
      ref="markTag"
      @refreshDataList="getDataList"
      :userType="userType"
      :labelIds="selectedLabelIds"
    ></mark-tag>
    <!-- 打标签 -->
    <!-- 发送随访筛查 -->
    <send-screening v-if="sendScreeningDialog" ref="sendScreening" @refreshDataList="getDataList"></send-screening>
    <!-- 发送随访筛查 -->
    <!-- 发送方案 -->
    <send-package v-if="sendPackageDialog" ref="sendPackage" @refreshDataList="getDataList"></send-package>
    <!-- 发送方案 -->
  </div>
</template>

<script>
import AddOrUpdate from "@/views/mbyhgl/mbyh/components/addOrUpdate";
import MarkTag from "@/views/mbscyh/mbsc/components/markTag";
import SendScreening from "@/views/mbyhgl/mbyh/components/sendScreening";
import SendPackage from "@/views/mbyhgl/mbyh/components/sendPackage";
import qs from "qs";
export default {
  components: {
    AddOrUpdate,
    MarkTag,
    SendScreening,
    SendPackage,
  },
  data() {
    return {
      visible: false,
      dataList: [], //列表数据
      dataListLoading: false, //列表loading
      dataListSelections: [], //选中的列
      pageNo: 1,
      pageSize: 10,
      totalPage: 0, //总页数
      addOrUpdateDialog: false, //显示新增或编辑筛查
      markTagDialog: false, //显示打标签
      userType: 2, //慢病用户类型
      selectedLabelIds: [], //选中的标签
      sendScreeningDialog: false, //显示随访筛查
      sendPackageDialog: false, //发送方案
      json: {},
    };
  },
  methods: {
    init(json) {
      let _this = this;
      _this.visible = true;
      _this.json = json;
      _this.getDataList();
    },
    // 获取列表
    getDataList() {
      let that = this;
      let params = {
        pageSize: that.pageSize,
        pageNo: that.pageNo,
        json: that.json,
        mbglResourceType: that.sourceValue,
        mbglReasonType: that.reasonValue,
      };
      that.dataListLoading = true;
      that
        .$http({
          url: that.$http.adornUrl("/teamsPatience/getPatienceBySearch"),
          method: "get",
          params: params,
        })
        .then(({ data }) => {
          if (data.status) {
            that.dataList = data.data;
            that.dataListLoading = false;
            that.totalPage = data.totalCount;
          }
        });
    },
    handelChange(val) {
      this.getDataList();
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageNo = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageNo = val;
      this.getDataList();
    },
    // 多选
    selectionChangeHandle(val) {
      this.dataListSelections = val;
    },
    confirmed() {
      let that = this;
      let ids = null;
      if (that.dataListSelections.length) {
        ids = that.dataListSelections.map((item) => {
          return item.id;
        });
        ids = ids.join(",");
      }
      that
        .$confirm(
          "用户将要回到对应病种的筛查用户中，并且筛查原因将要设定为康复患者，保留全部的随访和方案记录",
          "康复",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
        .then(() => {
          that
            .$http({
              url: this.$http.adornUrl(
                "/teamsPatience/updateTeamsPatienceTypeById"
              ),
              method: "post",
              data: qs.stringify({ ids: ids, type: 1 }),
            })
            .then(({ data }) => {
              if (data.status) {
                that.$message({
                  message: data.msg,
                  type: "success",
                  duration: 1500,
                  onClose: () => {
                    that.getDataList();
                  },
                });
              } else {
                that.$message.error(data.msg);
              }
            });
        })
        .catch(() => {});
    },
    // 新增或编辑筛查
    showAddOrUpdate(rows) {
      let _this = this;
      _this.addOrUpdateDialog = true;
      _this.$nextTick(() => {
        _this.$refs.addOrUpdate.init(rows);
      });
    },
    //打标签
    showMarkTag(type, row) {
      let _this = this;
      let ids = [];
      if (type == 1) {
        ids = _this.dataListSelections.map((item) => {
          return item.id;
        });
      } else {
        ids.push(row.id);
        let selectedLabelIds = [];
        if (row.label_id) {
          let arr = row.label_id.split(",");
          if (arr.length) {
            arr.forEach((ele, index) => {
              selectedLabelIds.push({
                id: ele,
                // labelName: row.label_name.split(",")[index]
              });
            });
          }
          _this.selectedLabelIds = selectedLabelIds;
        }
      }
      _this.markTagDialog = true;
      _this.$nextTick(() => {
        _this.$refs.markTag.init(ids);
      });
    },
    //发送随访筛查
    showSendScreening(type, row) {
      let _this = this;
      let patienceIds = [];
      if (type == 1) {
        patienceIds = _this.dataListSelections.map((item) => {
          return item.patience_id;
        });
      } else {
        patienceIds.push(row.patience_id);
      }
      _this.sendScreeningDialog = true;
      _this.$nextTick(() => {
        _this.$refs.sendScreening.init(patienceIds);
      });
    },
    // 发送方案弹窗
    showSendPackage(type, row) {
      let _this = this;
      let patienceIds = [];
      if (type == 1) {
        patienceIds = _this.dataListSelections.map((item) => {
          return item.patience_id;
        });
      } else {
        patienceIds.push(row.patience_id);
      }
      _this.sendPackageDialog = true;
      _this.$nextTick(() => {
        _this.$refs.sendPackage.init(patienceIds);
      });
    },
    // 删除
    deleteHandle(item) {
      let that = this;
      let ids = null;
      if (item && item.id) {
        ids = item.id;
      } else {
        ids = that.dataListSelections.map((item) => {
          return item.id;
        });
        ids = ids.join(",");
      }
      that
        .$confirm(`确定进行删除操作?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          that
            .$httpAes({
              url: this.$httpAes.adornUrl("/teamsPatience/delete"),
              method: "post",
              data: { stringParam1: ids },
            })
            .then(({ data }) => {
              if (data.status) {
                that.$message({
                  message: data.msg,
                  type: "success",
                  duration: 1500,
                  onClose: () => {
                    that.getDataList();
                  },
                });
              } else {
                that.$message.error(data.msg);
              }
            });
        })
        .catch(() => {});
    },
    // 进入详情
    handleRowInfo(row) {
      let _this = this;
      _this.$router.push({ name: "mbyhglInfo", query: { id: row.id } });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>