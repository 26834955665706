<template>
  <div class="map-chart" id="mapEchart"></div>
</template>

<script>
import * as echarts from "echarts";
import anhui from "@/assets/anhui.json";
import { myEchartsFontSize } from "@/utils";
export default {
  props: ["data"],
  data() {
    return {
      mapChart: "",
    };
  },
  methods: {
    async getMapChart() {
      let _this = this;
      console.log(_this.data);
      this.mapChart = echarts.init(document.getElementById("mapEchart"));
      echarts.registerMap("FeatureCollection", anhui);
      let option = {
        tooltip: {
          textStyle: {
            fontSize: myEchartsFontSize(14, 1), // 设置字体大小为14
          },
          formatter: function (e) {
            var name = e.name ? e.name : "获取中";
            var value = e.value ? e.value : "暂无数据";

            var res = "";
            if (e.data && e.data.list && e.data.list.length) {
              for (var i = 0; i < e.data.list.length; i++) {
                res += `<div>${e.data.list[i].name}:${e.data.list[i].value}</div>`;
              }
            }
            if (e.data && e.data.list && e.data.list.length) {
              return res;
            } else {
              return name + "：" + value;
            }
          },
        },
        visualMap: {
          min: 0,
          max: 1322,
          left: "5%",
          bottom: 20,
          text: ['高', '低'],//两端的文本，如 ['High', 'Low']
          calculable: true,
          textStyle: {
            // color: "#fff",
            fontSize: myEchartsFontSize(14,1)
          },
          inRange: {
            color: [
              "#D7F9FE",
              "#B5E3F8",
              "#7EBFF0",
              "#4FA1E9",
            ],
          },
          show: true,
        },
        // geo: {
        //   map: "china",
        //   roam: false,
        //   zoom: 1.1,
        //   label: {
        //     emphasis: {
        //       show: false,
        //     },
        //   },
        //   itemStyle: {
        //     borderColor: "transparent",
        //     areaColor: "transparent",
        //   },
        // },
        series: [
          {
            type: "map",
            map: "FeatureCollection",
            zlevel: 6,
            zoom: 1.1,
            itemStyle: {
              borderWidth: 1,
              borderColor: "rgba(255,255,255,.3)",
              emphasis: {
                borderColor: "#40C9EA",
                shadowColor: "rgba(3,221,255,0.4)",
                shadowBlur: myEchartsFontSize(15,1),
                label: {
                  color: "#333",
                },
                areaColor: {
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "#D7F9FE",
                    },
                    {
                      offset: 1,
                      color: "#4FA1E9",
                    },
                  ],
                },
              },
            },
            data: _this.data,
          },
        ],
      };
      this.mapChart.setOption(option);
      // this.$nextTick(() => {
      //   window.onresize = function () {
      //     setTimeout(() => {
      //       _this.mapChart.resize();
      //     }, 100);
      //   };
      // });
      window.addEventListener("resize", () => {
        if (this.mapChart) {
          this.mapChart.resize();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.map-chart {
  width: 100%;
  height: 100%;
}
</style>
