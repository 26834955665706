<template>
  <div class="home-page" ref="scale">
    <div class="block">
      <div class="block-item" v-loading="loading">
        <div class="item-title">人员数据汇总</div>
        <div class="staff-chart">
          <div class="patient-num">
            <pie
              :data="patientNum"
              ref="pieChart"
              title="患者总数"
              idName="patientChart"
            ></pie>
			<!-- <pie
			  :data="4504"
			  ref="pieChart"
			  title="患者总数"
			  idName="patientChart"
			></pie> -->
          </div>
          <div class="patient-num">
            <pie
              :data="seeNum"
              ref="pieChart2"
              title="就诊次数"
              idName="seeChart"
              sourceType="2"
            ></pie>
          </div>
        </div>
        <div class="month-num">
          <div class="tab">
            <div
              class="tab-item"
              :class="{ active: activeName == 1 }"
              @click="changeTab(1)"
            >
              <div class="item-name">患者人数</div>
              <div class="line" v-if="activeName == 1"></div>
            </div>
            <div
              class="tab-item"
              :class="{ active: activeName == 2 }"
              @click="changeTab(2)"
            >
              <div class="item-name">就诊次数</div>
              <div class="line" v-if="activeName == 2"></div>
            </div>
          </div>
          <div class="detail">
            <div class="detail-item">
              本月累计<span class="num" v-if="countPatient.month">{{
                countPatient.month.number
              }}</span>
            </div>
            <div class="detail-item">
              环比<span class="num">{{ countPatient.chainRatio }}</span>
            </div>
            <div class="detail-item">
              本年累计<span class="num" v-if="countPatient.year">{{
                countPatient.year.number
              }}</span>
            </div>
            <div class="detail-item">
              同比<span class="num">{{ countPatient.commonRatio }}</span>
            </div>
          </div>
          <div class="month-bar">
            <bar
              :xAxisList="xAxisList"
              :yAxisList="yAxisList"
              idName="monthNum"
              ref="barChart"
            ></bar>
          </div>
        </div>
      </div>
      <div class="block-item">
        <div class="area-num">
          <map-chart :data="mapList" ref="mapChart"></map-chart>
          <!-- <div class="color-list">
            <div class="item">
              <div class="item-bg"></div>
              <div class="item-text">0-200</div>
            </div>
          </div> -->
        </div>
      </div>
      <div class="block-item">
        <div class="block-top">
          <div class="item-title">治疗方案</div>
          <div class="tab-btns">
            <div
              class="btn"
              v-for="(item, index) in planList"
              :key="index"
              :class="{ active: planCheck == item.value }"
              @click="changePlan(item.value)"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="plan-chart">
          <pie
            v-if="planCheck == 1"
            :data="allPlanList"
            ref="planPieChart"
            idName="allPlan"
            :isSolid="true"
          ></pie>
          <bar
            v-else
            :xAxisList="xAxisPlanList"
            :yAxisList="yAxisPlanList"
            idName="planBarChart"
            ref="planBarChart"
            :isOrientation="true"
            color="#1DD0DF"
          ></bar>
        </div>
      </div>
    </div>
    <div class="block">
      <div class="block-item block-item-2">
        <div class="block-top">
          <div class="item-title">疾病诊断</div>
          <div class="tab-btns">
            <div
              class="btn"
              v-for="(item, index) in diseaseList"
              :key="index"
              :class="{ active: diseaseCheck == item.value }"
              @click="changeDisease(item.value)"
            >
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="echarts-box" :class="{ 'bar-width': diseaseCheck == 5 }">
          <div
            class="disease-pie-chart"
            id="diseasePieChart"
            v-if="diseaseCheck != 5"
          ></div>
          <bar
            v-else
            :xAxisList="xAxisWesternList"
            :yAxisList="yAxisWesternList"
            idName="westernBarChart"
            ref="westernBarChart"
            type="western"
          ></bar>
        </div>
      </div>
      <div class="block-item block-item-3" v-loading="dataListLoading">
        <div class="item-title">首页内容</div>
        <div class="information-list" v-if="informationList.length">
          <div
            class="list-item"
            v-for="(item, index) in informationList"
            :key="index"
            @click="handleViewInfo(item)"
          >
            <div class="date">{{ item.publishTime }}</div>
            <div class="title">{{ item.noticeTitle }}</div>
          </div>
        </div>
        <div class="no-data">暂无数据</div>
        <div class="more-btn" v-if="total > 8" @click="toInformation">
          查看更多
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pie from "./component/pie";
import bar from "./component/bar";
import mapChart from "./component/map";
import { myEchartsFontSize } from "@/utils";
export default {
  inject: ["refresh"],
  components: {
    pie,
    bar,
    mapChart,
  },
  data() {
    return {
      patientNum: [],
      seeNum: [],
      year: "",
      loading: false,
      teamsId: "",
      doctorId: "",
      activeName: "1",
      countPatient: {}, //本年本月
      xAxisList: [], //横坐标
      yAxisList: [], //纵坐标
      month: [
        "一月",
        "二月",
        "三月",
        "四月",
        "五月",
        "六月",
        "七月",
        "八月",
        "九月",
        "十月",
        "十一月",
        "十二月",
      ],
      mapList: [
        { name: "宿州市", value: 1, list: [{ name: "萧县", value: 1 }] },
        { name: "淮北市", value: 301, list: [] },
        {
          name: "亳州市",
          value: 105,
          list: [
            { name: "谯城区", value: 2 },
            { name: "其他", value: 103 },
          ],
        },
        {
          name: "阜阳市",
          value: 210,
          list: [
            { name: "临泉县", value: 1 },
            { name: "太和县", value: 1 },
            { name: "其他", value: 208 },
          ],
        },
        {
          name: "淮南市",
          value: 19,
          list: [
            { name: "田家庵区", value: 2 },
            { name: "寿县", value: 1 },
            { name: "大通区", value: 1 },
            { name: "凤台县", value: 1 },
            { name: "其他", value: 14 },
          ],
        },
        { name: "滁州市", value: 205, list: [] },
        {
          name: "六安市",
          value: 335,
          list: [
            { name: "霍邱县", value: 2 },
            { name: "其他", value: 333 },
          ],
        },
        {
          name: "合肥市",
          value: 1322,
          list: [
            { name: "肥西县", value: 3 },
            { name: "蜀山区", value: 2 },
            { name: "庐阳区", value: 1 },
            { name: "庐江县", value: 1 },
            { name: "肥东县", value: 1 },
            { name: "包河区", value: 1 },
            { name: "其他", value: 1313 },
          ],
        },
        { name: "安庆市", value: 1, list: [{ name: "太湖县", value: 1 }] },
        { name: "芜湖市", value: 379, list: [] },
      ],
      mapList2: [
        { name: "徐州市", value: 1, list: [{ name: "萧县", value: 1 }] },
        { name: "连云港市", value: 301, list: [] },
        {
          name: "宿迁市",
          value: 105,
          list: [
            // { name: "谯城区", value: 2 },
            // { name: "其他", value: 103 },
          ],
        },
        {
          name: "淮安市",
          value: 210,
          list: [
            // { name: "临泉县", value: 1 },
            // { name: "太和县", value: 1 },
            // { name: "其他", value: 208 },
          ],
        },
        {
          name: "盐城市",
          value: 19,
          list: [
           
          ],
        },
        { name: "扬州市", value: 205, list: [] },
        {
          name: "盐城市",
          value: 335,
        },
        {
          name: "南京市",
          value: 1322,
        },
        { name: "泰州市", value: 1, list: [] },
        { name: "镇江市", value: 379, list: [] },
        { name: "南通市", value: 120, list: [] },
        { name: "常州市", value: 58, list: [] },
        { name: "无锡市", value: 258, list: [] },
        { name: "苏州市", value: 1258, list: [] },
      ],
      planCheck: 1,
      planList: [
        { name: "全部", value: 1 },
        { name: "中药", value: 2 },
        { name: "西药", value: 3 },
        { name: "中成药", value: 4 },
        { name: "非药物", value: 5 },
      ],
      allPlanList: [
        { name: "中药人数", value: 2479 },
        { name: "西药治疗", value: 4035 },
        { name: "中成药治疗", value: 296 },
        { name: "其他药物治疗", value: 2533 },
        { name: "非药物治疗", value: 1425 },
      ], //治疗方案全部数据
      xAxisPlanList: [],
      yAxisPlanList: [],
      // 中药
      medicine: [
        { name: "炙甘草", value: 1406 },
        { name: "苦杏仁", value: 1141 },
        { name: "紫苏子", value: 1055 },
        { name: "浙贝母", value: 964 },
        { name: "陈皮", value: 845 },
        { name: "姜半夏", value: 779 },
        { name: "葶苈子", value: 733 },
        { name: "黄芩", value: 707 },
        { name: "法半夏", value: 697 },
        { name: "甘草", value: 679 },
      ],
      // 西药
      medicine2: [
        { name: "受体激动剂", value: 183 },
        { name: "抗胆碱药", value: 163 },
        { name: "茶碱类", value: 163 },
        { name: "激素", value: 225 },
        { name: "磷酸二酯酶抑制剂", value: 1 },
        { name: "祛痰药", value: 173 },
        { name: "抗氧化剂", value: 58 },
        { name: "康然治疗", value: 192 },
      ],
      // 中成药
      medicine3: [
        { name: "杏苏止咳颗粒", value: 279 },
        { name: "感冒疏风颗粒", value: 5 },
        { name: "蛇胆川贝液", value: 1 },
        { name: "清肺消炎丸", value: 1 },
        { name: "痰热清注射液", value: 103 },
        { name: "桂龙咳喘宁胶囊", value: 8 },
        { name: "苓桂咳喘宁胶囊", value: 2 },
        { name: "苏子降气丸", value: 7 },
        { name: "清开灵注射液", value: 5 },
        { name: "玉屏风冲剂", value: 49 },
        { name: "百令胶囊", value: 5 },
        { name: "生脉饮口服液", value: 4 },
        { name: "哈蚧定喘丸", value: 6 },
        { name: "麦味地黄丸", value: 1 },
      ],
      // 非药物
      medicine4: [
        { name: "家庭氧疗", value: 261 },
        { name: "冬病夏治", value: 24 },
        { name: "中药离子导入法", value: 24 },
        { name: "呼吸操", value: 10 },
        { name: "中药雾化吸入", value: 9 },
        { name: "针灸拔罐法", value: 30 },
        { name: "督灸疗法", value: 28 },
        { name: "耳穴压豆疗法", value: 468 },
        { name: "穴位注射", value: 59 },
        { name: "埋线疗法", value: 4 },
        { name: "冬令膏方调补", value: 4 },
        { name: "太极拳", value: 4 },
        { name: "气功", value: 4 },
      ],
      diseasePieChart: "",
      diseaseCheck: 1,
      diseaseList: [
        { name: "中医诊断", value: 1 },
        { name: "肺胀", value: 2 },
        { name: "咳嗽", value: 3 },
        { name: "喘证", value: 4 },
        { name: "西医诊断", value: 5 },
      ],
      diseasePieList: [
        {
          name: "肺胀",
          value: 2755,
          itemStyle: {
            normal: { color: "#4dcb6c" },
            emphasis: { color: "#4dcb6c" },
          },
        },
        {
          name: "咳嗽",
          value: 367,
          itemStyle: {
            normal: { color: "#7cb5ec" },
            emphasis: { color: "#7cb5ec" },
          },
        },
        {
          name: "喘证",
          value: 1054,
          itemStyle: {
            normal: { color: "#8085e9" },
            emphasis: { color: "#8085e9" },
          },
        },
      ],
      diseasePieSecList: [
        {
          name: "稳定期",
          value: "256",
          itemStyle: {
            normal: { color: "#6BE98A" },
            emphasis: { color: "#6BE98A" },
          },
        },
        {
          name: "急性加重期",
          value: "2499",
          itemStyle: {
            normal: { color: "#75F394" },
            emphasis: { color: "#75F394" },
          },
        },
        {
          name: "外伤咳嗽",
          value: "187",
          itemStyle: {
            normal: { color: "#9AD3FF" },
            emphasis: { color: "#9AD3FF" },
          },
        },
        {
          name: "内伤咳嗽",
          value: "180",
          itemStyle: {
            normal: { color: "#A4DDFF" },
            emphasis: { color: "#A4DDFF" },
          },
        },
        {
          name: "实喘",
          value: "846",
          itemStyle: {
            normal: { color: "#9096FF" },
            emphasis: { color: "#9096FF" },
          },
        },
        {
          name: "虚喘",
          value: "208",
          itemStyle: {
            normal: { color: "#787EF4" },
            emphasis: { color: "#787EF4" },
          },
        },
      ],
      diseasePieThirdList: [
        {
          name: "肺气虚",
          value: "31",
          itemStyle: {
            normal: { color: "#9DFFBC" },
            emphasis: { color: "#9DFFBC" },
          },
        },
        {
          name: "肺脾气虚",
          value: "22",
          itemStyle: {
            normal: { color: "#9DFFBC" },
            emphasis: { color: "#9DFFBC" },
          },
        },
        {
          name: "肺肾气虚",
          value: "177",
          itemStyle: {
            normal: { color: "#9DFFBC" },
            emphasis: { color: "#9DFFBC" },
          },
        },
        {
          name: "肺肾气阴两虚",
          value: "26",
          itemStyle: {
            normal: { color: "#9DFFBC" },
            emphasis: { color: "#9DFFBC" },
          },
        },
        {
          name: "风寒袭肺",
          value: "150",
          itemStyle: {
            normal: { color: "#89FFA8" },
            emphasis: { color: "#89FFA8" },
          },
        },
        {
          name: "外寒内饮",
          value: "57",
          itemStyle: {
            normal: { color: "#89FFA8" },
            emphasis: { color: "#89FFA8" },
          },
        },
        {
          name: "痰热壅肺",
          value: "990",
          itemStyle: {
            normal: { color: "#89FFA8" },
            emphasis: { color: "#89FFA8" },
          },
        },
        {
          name: "痰湿阻肺",
          value: "1375",
          itemStyle: {
            normal: { color: "#89FFA8" },
            emphasis: { color: "#89FFA8" },
          },
        },
        {
          name: "痰蒙神窍",
          value: "11",
          itemStyle: {
            normal: { color: "#89FFA8" },
            emphasis: { color: "#89FFA8" },
          },
        },
        {
          name: "风寒袭肺",
          value: "153",
          itemStyle: {
            normal: { color: "#CCFFFF" },
            emphasis: { color: "#CCFFFF" },
          },
        },
        {
          name: "风热犯肺",
          value: "24",
          itemStyle: {
            normal: { color: "#CCFFFF" },
            emphasis: { color: "#CCFFFF" },
          },
        },
        {
          name: "风燥伤肺",
          value: "10",
          itemStyle: {
            normal: { color: "#CCFFFF" },
            emphasis: { color: "#CCFFFF" },
          },
        },
        {
          name: "痰湿蕴肺",
          value: "105",
          itemStyle: {
            normal: { color: "#B8F1FF" },
            emphasis: { color: "#B8F1FF" },
          },
        },
        {
          name: "痰热郁肺",
          value: "85",
          itemStyle: {
            normal: { color: "#B8F1FF" },
            emphasis: { color: "#B8F1FF" },
          },
        },
        {
          name: "肝火犯肺",
          value: "0",
          itemStyle: {
            normal: { color: "#B8F1FF" },
            emphasis: { color: "#B8F1FF" },
          },
        },
        {
          name: "肺阴亏耗",
          value: "17",
          itemStyle: {
            normal: { color: "#B8F1FF" },
            emphasis: { color: "#B8F1FF" },
          },
        },
        {
          name: "风寒袭肺",
          value: "173",
          itemStyle: {
            normal: { color: "#B2B7FF" },
            emphasis: { color: "#B2B7FF" },
          },
        },
        {
          name: "表寒里热",
          value: "18",
          itemStyle: {
            normal: { color: "#B2B7FF" },
            emphasis: { color: "#B2B7FF" },
          },
        },
        {
          name: "痰热郁肺",
          value: "161",
          itemStyle: {
            normal: { color: "#B2B7FF" },
            emphasis: { color: "#B2B7FF" },
          },
        },
        {
          name: "痰浊阻肺",
          value: "468",
          itemStyle: {
            normal: { color: "#B2B7FF" },
            emphasis: { color: "#B2B7FF" },
          },
        },
        {
          name: "肺气郁痹",
          value: "26",
          itemStyle: {
            normal: { color: "#B2B7FF" },
            emphasis: { color: "#B2B7FF" },
          },
        },
        {
          name: "肺气虚",
          value: "153",
          itemStyle: {
            normal: { color: "#D0D5FF" },
            emphasis: { color: "#D0D5FF" },
          },
        },
        {
          name: "肾气虚",
          value: "55",
          itemStyle: {
            normal: { color: "#D0D5FF" },
            emphasis: { color: "#D0D5FF" },
          },
        },
        {
          name: "喘脱",
          value: "0",
          itemStyle: {
            normal: { color: "#D0D5FF" },
            emphasis: { color: "#D0D5FF" },
          },
        },
      ],
      diseaseFZPieList: [
        {
          name: "肺胀",
          value: 2755,
          itemStyle: {
            normal: { color: "#4dcb6c" },
            emphasis: { color: "#4dcb6c" },
          },
        },
      ],
      diseaseFZSecPieList: [
        {
          name: "稳定期",
          value: "256",
          itemStyle: {
            normal: { color: "#6BE98A" },
            emphasis: { color: "#6BE98A" },
          },
        },
        {
          name: "急性加重期",
          value: "2499",
          itemStyle: {
            normal: { color: "#75F394" },
            emphasis: { color: "#75F394" },
          },
        },
      ],
      diseaseFZThirdPieList: [
        {
          name: "肺气虚",
          value: "31",
          itemStyle: {
            normal: { color: "#9DFFBC" },
            emphasis: { color: "#9DFFBC" },
          },
        },
        {
          name: "肺脾气虚",
          value: "22",
          itemStyle: {
            normal: { color: "#9DFFBC" },
            emphasis: { color: "#9DFFBC" },
          },
        },
        {
          name: "肺肾气虚",
          value: "177",
          itemStyle: {
            normal: { color: "#9DFFBC" },
            emphasis: { color: "#9DFFBC" },
          },
        },
        {
          name: "肺肾气阴两虚",
          value: "26",
          itemStyle: {
            normal: { color: "#9DFFBC" },
            emphasis: { color: "#9DFFBC" },
          },
        },
        {
          name: "风寒袭肺",
          value: "150",
          itemStyle: {
            normal: { color: "#89FFA8" },
            emphasis: { color: "#89FFA8" },
          },
        },
        {
          name: "外寒内饮",
          value: "57",
          itemStyle: {
            normal: { color: "#89FFA8" },
            emphasis: { color: "#89FFA8" },
          },
        },
        {
          name: "痰热壅肺",
          value: "990",
          itemStyle: {
            normal: { color: "#89FFA8" },
            emphasis: { color: "#89FFA8" },
          },
        },
        {
          name: "痰湿阻肺",
          value: "1375",
          itemStyle: {
            normal: { color: "#89FFA8" },
            emphasis: { color: "#89FFA8" },
          },
        },
        {
          name: "痰蒙神窍",
          value: "11",
          itemStyle: {
            normal: { color: "#89FFA8" },
            emphasis: { color: "#89FFA8" },
          },
        },
      ],
      diseaseHSPieList: [
        {
          name: "咳嗽",
          value: 367,
          itemStyle: {
            normal: { color: "#7cb5ec" },
            emphasis: { color: "#7cb5ec" },
          },
        },
      ],
      diseaseHSSecPieList: [
        {
          name: "外伤咳嗽",
          value: "187",
          itemStyle: {
            normal: { color: "#9AD3FF" },
            emphasis: { color: "#9AD3FF" },
          },
        },
        {
          name: "内伤咳嗽",
          value: "180",
          itemStyle: {
            normal: { color: "#A4DDFF" },
            emphasis: { color: "#A4DDFF" },
          },
        },
      ],
      diseaseHSThirdPieList: [
        {
          name: "风寒袭肺",
          value: "153",
          itemStyle: {
            normal: { color: "#CCFFFF" },
            emphasis: { color: "#CCFFFF" },
          },
        },
        {
          name: "风热犯肺",
          value: "24",
          itemStyle: {
            normal: { color: "#CCFFFF" },
            emphasis: { color: "#CCFFFF" },
          },
        },
        {
          name: "风燥伤肺",
          value: "10",
          itemStyle: {
            normal: { color: "#CCFFFF" },
            emphasis: { color: "#CCFFFF" },
          },
        },
        {
          name: "痰湿蕴肺",
          value: "105",
          itemStyle: {
            normal: { color: "#B8F1FF" },
            emphasis: { color: "#B8F1FF" },
          },
        },
        {
          name: "痰热郁肺",
          value: "85",
          itemStyle: {
            normal: { color: "#B8F1FF" },
            emphasis: { color: "#B8F1FF" },
          },
        },
        {
          name: "肝火犯肺",
          value: "0",
          itemStyle: {
            normal: { color: "#B8F1FF" },
            emphasis: { color: "#B8F1FF" },
          },
        },
        {
          name: "肺阴亏耗",
          value: "17",
          itemStyle: {
            normal: { color: "#B8F1FF" },
            emphasis: { color: "#B8F1FF" },
          },
        },
      ],
      diseaseCHPieList: [
        {
          name: "喘证",
          value: 1054,
          itemStyle: {
            normal: { color: "#8085e9" },
            emphasis: { color: "#8085e9" },
          },
        },
      ],
      diseaseCHSecPieList: [
        {
          name: "实喘",
          value: "846",
          itemStyle: {
            normal: { color: "#9096FF" },
            emphasis: { color: "#9096FF" },
          },
        },
        {
          name: "虚喘",
          value: "208",
          itemStyle: {
            normal: { color: "#787EF4" },
            emphasis: { color: "#787EF4" },
          },
        },
      ],
      diseaseCHThirdPieList: [
        {
          name: "风寒袭肺",
          value: "173",
          itemStyle: {
            normal: { color: "#B2B7FF" },
            emphasis: { color: "#B2B7FF" },
          },
        },
        {
          name: "表寒里热",
          value: "18",
          itemStyle: {
            normal: { color: "#B2B7FF" },
            emphasis: { color: "#B2B7FF" },
          },
        },
        {
          name: "痰热郁肺",
          value: "161",
          itemStyle: {
            normal: { color: "#B2B7FF" },
            emphasis: { color: "#B2B7FF" },
          },
        },
        {
          name: "痰浊阻肺",
          value: "468",
          itemStyle: {
            normal: { color: "#B2B7FF" },
            emphasis: { color: "#B2B7FF" },
          },
        },
        {
          name: "肺气郁痹",
          value: "26",
          itemStyle: {
            normal: { color: "#B2B7FF" },
            emphasis: { color: "#B2B7FF" },
          },
        },
        {
          name: "肺气虚",
          value: "153",
          itemStyle: {
            normal: { color: "#D0D5FF" },
            emphasis: { color: "#D0D5FF" },
          },
        },
        {
          name: "肾气虚",
          value: "55",
          itemStyle: {
            normal: { color: "#D0D5FF" },
            emphasis: { color: "#D0D5FF" },
          },
        },
        {
          name: "喘脱",
          value: "0",
          itemStyle: {
            normal: { color: "#D0D5FF" },
            emphasis: { color: "#D0D5FF" },
          },
        },
      ],
      westernDiagnosis: [
        { name: "慢性阻塞性肺病伴有急性下呼吸道感染", value: 69 },
        { name: "慢性阻塞性支气管炎合并感染", value: 16 },
        { name: "性阻塞性肺病伴有急性加重", value: 3453 },
        { name: "慢性阻塞性肺气肿性支气管炎伴急性加重", value: 42 },
        { name: "慢性阻塞性肺病", value: 11 },
        { name: "哮喘-慢阻肺重叠综合征", value: 17 },
        { name: "慢性阻塞性肺病", value: 282 },
        { name: "慢性阻塞性肺疾病I级", value: 0 },
        { name: "慢性阻塞性肺疾病II级", value: 0 },
        { name: "慢性阻塞性肺疾病III级", value: 0 },
        { name: "慢性阻塞性肺疾病IV级", value: 13 },
      ],
      xAxisWesternList: [],
      yAxisWesternList: [],
      informationList: [],
      pageNo: 1,
      dataListLoading: false,
      total: 0,
    };
  },
  mounted() {
    this.year = new Date().getFullYear().toString();
    if (localStorage.userInfo) {
      this.doctorId = JSON.parse(localStorage.userInfo).id;
    }
    if (localStorage.currentTeam) {
      this.teamsId = JSON.parse(localStorage.currentTeam).id;
      this.getPatientNum();
      this.getPatientNumByMonth();
      this.getCountPatience();
    }
    this.$nextTick(() => {
      this.$refs.mapChart.getMapChart();
    });
    this.$nextTick(() => {
      this.$refs.planPieChart.getCircleChart();
    });
    this.$nextTick(() => {
      this.getDiseasePieChart();
    });
    this.getList();
  },
  methods: {
    fontSizeRem(size) {
      const clientWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      if (!clientWidth) return;
      let fontSize = clientWidth / 1920; //尺寸大小
      return size * fontSize;
    },
    // 改变患者的tab
    changeTab(num) {
      this.activeName = num;
      this.getPatientNumByMonth();
      this.getCountPatience();
    },
    // 获取患者和就诊人数量
    async getPatientNum() {
      this.loading = true;
      const { data: res } = await this.$httpAes({
        url: this.$httpAes.adornUrl("/homePage/selectCountPatience"),
        method: "post",
        data: {
          stringParam1: this.teamsId,
          year: this.year,
          stringParam2: this.doctorId,
        },
      });
      if (res.status) {
        this.loading = false;
        if (res.data && res.data.patientCount && res.data.patientCount.length) {
          for (let key in res.data.patientCount[0]) {
            let obj = {};
            if (key == "thirty") {
              obj.name = "30岁以下";
              obj.index = 1;
            }
            if (key == "forty") {
              obj.name = "30-40岁";
              obj.index = 2;
            }
            if (key == "fifty") {
              obj.name = "40-50岁";
              obj.index = 3;
            }
            if (key == "sixty") {
              obj.name = "50-60岁";
              obj.index = 4;
            }
            if (key == "other") {
              obj.name = "60岁以上";
              obj.index = 5;
            }
            obj.value = res.data.patientCount[0][key];
            this.patientNum.push(obj);
          }
        }
        if (this.patientNum.length) {
          let arr = this.patientNum.sort((a, b) => {
            return a.index - b.index;
          });
		   console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!patientNum')
		  console.log(arr)
          this.patientNum = arr;
          this.$nextTick(() => {
            this.$refs.pieChart.getCircleChart();
          });
        }
        if (res.data && res.data.visitCount && res.data.visitCount.length) {
          res.data.visitCount.forEach((ele) => {
            let obj = {
              value: ele.number,
            };
            if (ele.visitType == 0) {
              obj.name = "初诊";
            }
            if (ele.visitTypeName) {
              obj.name = ele.visitTypeName;
            }
            this.seeNum.push(obj);
          });
        }
        if (this.seeNum.length) {
          this.$nextTick(() => {
            this.$refs.pieChart2.getCircleChart();
          });
        }
      }
    },
    // 获取患者和就诊人数量
    async getCountPatience() {
      const { data: res } = await this.$httpAes({
        url: this.$httpAes.adornUrl(
          this.activeName == 1
            ? "/homePage/getCountPatience"
            : "/homePage/getCountVisitInfo"
        ),
        method: "post",
        params: {
          stringParam1: this.teamsId,
          stringParam4: this.year,
          stringParam2: this.doctorId,
        },
      });
      if (res.status) {
        this.countPatient = res.data;
        this.countPatient.chainRatio = "0%";
        if (this.countPatient.lastMonth.number) {
          (this.countPatient.chainRatio =
            ((this.countPatient.month.number -
              this.countPatient.lastMonth.number) /
              this.countPatient.lastMonth.number) *
            100).toFixed(2) + "%";
        }
        this.countPatient.commonRatio = "0%";
        if (this.countPatient.lastYearMonth.number) {
          this.countPatient.commonRatio =
            (
              ((this.countPatient.month.number -
                this.countPatient.lastYearMonth.number) /
                this.countPatient.lastYearMonth.number) *
              100
            ).toFixed(2) + "%";
        }
      }
    },
    // 获取患者和就诊人数量
    async getPatientNumByMonth() {
      this.xAxisList = [];
      this.yAxisList = [];
      this.loading = true;
      const { data: res } = await this.$httpAes({
        url: this.$httpAes.adornUrl(
          this.activeName == 1
            ? "/homePage/groupPatience"
            : "/homePage/groupVisitInfo"
        ),
        method: "post",
        data: {
          stringParam1: this.teamsId,
          stringParam3: this.year,
          stringParam2: this.doctorId,
        },
      });
      if (res.status) {
        this.loading = false;
        if (res.data && res.data.length) {
          res.data.forEach((ele) => {
            if (ele.time) {
              ele.timeNum = parseInt(ele.time);
              this.xAxisList.push(this.month[ele.timeNum - 1]);
              this.yAxisList.push(ele.number);
            }
          });
        }
        if (this.xAxisList.length) {
          this.$nextTick(() => {
            this.$refs.barChart.getBarChart();
          });
        }
        console.log(this.xAxisList);
      }
    },
    // 治疗方案头部切换数据
    changePlan(num) {
      this.planCheck = num;
      if (num == 1) {
        this.$nextTick(() => {
          this.$refs.planPieChart.getCircleChart();
        });
      } else {
        let list = [];
        if (num == 2) {
          list = this.medicine;
        }
        if (num == 3) {
          list = this.medicine2;
        }
        if (num == 4) {
          list = this.medicine3;
        }
        if (num == 5) {
          list = this.medicine4;
        }
        list = list.reverse();
        this.xAxisPlanList = list.map((ele) => {
          return ele.value;
        });
        this.yAxisPlanList = list.map((ele) => {
          return ele.name;
        });
        this.$nextTick(() => {
          this.$refs.planBarChart.getBarChart();
        });
      }
    },
    // 疾病诊断
    changeDisease(num) {
      this.diseaseCheck = num;
      if (num == 5) {
        if (this.westernDiagnosis.length) {
          this.xAxisWesternList = this.westernDiagnosis.map((ele) => {
            return ele.name;
          });
          this.yAxisWesternList = this.westernDiagnosis.map((ele) => {
            return ele.value;
          });
        }
        if (this.xAxisWesternList.length) {
          this.$nextTick(() => {
            this.$refs.westernBarChart.getBarChart();
          });
        }
      } else {
        this.$nextTick(() => {
          this.getDiseasePieChart(num);
        });
      }
    },
    getDiseasePieChart(num) {
      let _this = this;
      this.diseasePieChart = this.$echarts.init(
        document.getElementById("diseasePieChart")
      );
      let data = _this.diseasePieList;
      let data2 = _this.diseasePieSecList;
      let data3 = _this.diseasePieThirdList;
      if (num == 2) {
        data = _this.diseaseFZPieList;
        data2 = _this.diseaseFZSecPieList;
        data3 = _this.diseaseFZThirdPieList;
      }
      if (num == 3) {
        data = _this.diseaseHSPieList;
        data2 = _this.diseaseHSSecPieList;
        data3 = _this.diseaseHSThirdPieList;
      }
      if (num == 4) {
        data = _this.diseaseCHPieList;
        data2 = _this.diseaseCHSecPieList;
        data3 = _this.diseaseCHThirdPieList;
      }
      let option = {
        tooltip: {
          trigger: "item",
          formatter: "{b}: {c} ({d}%)",
        },
        series: [
          {
            type: "pie",
            selectedMode: "single",
            radius: [0, "40%"],
            center: ["50%", "50%"],
            color: ["#4dcb6c", "#8085e9", "#7cb5ec"],
            label: {
              position: "inner",
              fontSize: myEchartsFontSize(14, 1),
              color: "#fff",
            },
            itemStyle: {
              borderRadius: 0,
              borderColor: "#fff",
              borderWidth: myEchartsFontSize(2, 1),
              fontSize: myEchartsFontSize(14, 1),
            },
            labelLine: {
              show: false,
            },
            data: data,
            silent: num == 1 ? false : true,
          },
          {
            type: "pie",
            radius: ["40%", "80%"],
            center: ["50%", "50%"],
            color: [
              "#6BE98A",
              "#75F394",
              "#9AD3FF",
              "#A4DDFF",
              "#787EF4",
              "#9096FF",
            ],
            // labelLine: {
            //   length: 30,
            // },
            label: {
              position: "inner",
              fontSize: myEchartsFontSize(14, 1),
              color: "#fff",
            },
            itemStyle: {
              borderRadius: 0,
              borderColor: "#fff",
              borderWidth: myEchartsFontSize(2, 1),
              fontSize: myEchartsFontSize(14, 1),
            },
            labelLine: {
              show: false,
            },
            data: data2,
          },
          {
            type: "pie",
            radius: ["70%", "100%"],
            center: ["50%", "50%"],
            color: [
              "#c6cbff",
              "#7ffd9e",
              "#a4ddff",
              "#9ad3ff",
              "#a8adff",
              "#9ea3ff",
            ],
            // labelLine: {
            //   length: 30,
            // },
            label: {
              // position: "inner",
              fontSize: myEchartsFontSize(14, 1),
            },
            itemStyle: {
              borderRadius: 0,
              borderColor: "#fff",
              borderWidth: myEchartsFontSize(2, 1),
              fontSize: myEchartsFontSize(14, 1),
            },
            labelLine: {
              show: true,
            },
            data: data3,
          },
        ],
      };

      this.diseasePieChart.setOption(option);
      // this.diseasePieChart.on("mouseover", (e) => {
      //   console.log(e)
      //   let op = this.diseasePieChart.getOption();
      //   this.diseasePieChart.dispatchAction({
      //     type: "downplay",
      //     seriesIndex: 0,
      //     dataIndex: e.dataIndex,
      //     color: e.color,
      //   });
      //   this.diseasePieChart.setOption(op, true);
      // });
      this.$nextTick(() => {
        // window.onresize = function () {
        //   setTimeout(() => {
        //     _this.diseasePieChart.resize();
        //   }, 100);
        // };
        window.addEventListener("resize", () => {
          if (this.diseasePieChart) {
            this.diseasePieChart.resize();
          }
        });
      });
    },
    getList() {
      let teamsId = "";
      let enertyId = "";
      if (localStorage.currentTeam) {
        teamsId = JSON.parse(localStorage.currentTeam).id;
        enertyId = JSON.parse(localStorage.currentTeam).entityId;
      }
      let userInfo = {};
      if (localStorage.userInfo) {
        userInfo = JSON.parse(localStorage.userInfo);
      }
      this.dataListLoading = true;
      this.$httpAes({
        url: this.$httpAes.adornUrl("/notice/list"),
        method: "post",
        params: {
          intParam1: 8,
          intParam2: this.pageNo,
          stringParam4: teamsId,
          stringParam5: enertyId,
          intParam4: 2, //2资讯
        },
      }).then(({ data }) => {
        if (data.status) {
          let list = [];
          list = data.data;
          this.informationList = list;
          this.total = data.data.totalCount;
        } else {
          this.informationList = [];
        }
        this.dataListLoading = false;
      });
    },
    handleViewInfo(row) {
      this.$router.push({
        name: "informationDetail",
        query: {
          id: row.id,
        },
      });
    },
    toInformation() {
      this.$router.push({
        name: "information",
      });
    },
  },
};
</script>

<style lang="scss" scoped="scoped">
.home-page {
  .block {
    display: flex;
    width: 100%;
    height: 2.9791667rem;
    margin-bottom: 0.125rem;
    .block-item {
      // flex: 1;
      width: 33%;
      margin-right:1.33%;
      background: #fff;
      position: relative;
      &:last-child {
        margin-right: 0;
      }
      &.block-item-2 {
        width: 67.33%;
      }
      &.block-item-3 {
        // width: 31%;
      }
      .item-title {
        font-size: 0.09375rem;
        height: 0.296875rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 0.125rem;
        border-bottom: 1px solid #eeeeee;
        font-weight: bold;
      }
      .staff-chart {
        display: flex;
        .patient-num {
          width: 50%;
          height: 0.78125rem;
        }
      }
      .month-num {
        padding: 0 0.125rem;
        .tab {
          font-size: 0.0833rem;
          border-bottom: 1px solid #eee;
          display: flex;
          .tab-item {
            padding: 0.041667rem 0;
            margin-right: 0.208333rem;
            position: relative;
            width: 0.33333rem;
            cursor: pointer;
            &.active {
              font-weight: bold;
              color: #1890ff;
            }
            .item-name {
              margin-left: -0.02604167rem;
            }
            .line {
              position: absolute;
              width: 0.25520833rem;
              height: 0.01041667rem;
              background: #1890ff;
              left: 0.015625rem;
              bottom: 0;
            }
          }
        }
        .detail {
          width: 70%;
          overflow: hidden;
          font-size: 0.07291667rem;
          padding-top: 0.07850477rem;
          .detail-item {
            width: 40%;
            float: left;
            margin-right: 0.291667rem;
            margin-bottom: 0.03125rem;
            &:nth-child(2n) {
              margin-right: 0;
            }
            .num {
              padding-left: 0.125rem;
            }
          }
        }
        .month-bar {
          width: 100%;
          height: 1.22395833rem;
          margin-top: 0.1041667rem;
        }
      }
      .area-num {
        width: 100%;
        height: 100%;
      }
      .block-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 0.125rem;
        border-bottom: 1px solid #eeeeee;
        .item-title {
          font-size: 0.09375;
          border-bottom: none;
          font-weight: bold;
          padding: 0;
        }
        .tab-btns {
          display: flex;
          .btn {
            // width: 56px;
            height: 0.171875rem;
            border: 1px solid #e2e2e2;
            padding: 0 0.0842285rem;
            font-size: 0.0625rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border-right: none;
            cursor: pointer;
            &:last-child {
              border-right: 1px solid #e2e2e2;
            }
            &.active {
              background: #009cff;
              color: #fff;
            }
          }
        }
      }
      .plan-chart {
        width: 100%;
        height: 2.6041667rem;
      }
      .echarts-box {
        width: 100%;
        height: 75%;
        padding: 5% 0.125rem 0;
        &.bar-width {
          height: 90%;
        }

        .disease-pie-chart {
          width: 100%;
          height: 100%;
        }
      }
      .information-list {
        padding: 0 0.125rem;
        font-size: 0.07291667rem;
        max-height: 2.6041667rem;
        // overflow-y: scroll;
        .list-item {
          padding-top: 0.078125rem;
          .date {
            padding-bottom: 0.0208333rem;
          }
          .title {
            color: #009cff;
            text-overflow: ellipsis;
            cursor: pointer;
          }
        }
      }
      .more-btn {
        width: 100%;
        color: #999;
        cursor: pointer;
        position: absolute;
        bottom: 0.0520833rem;
        display: flex;
        justify-content: center;
        &:hover {
          color: #009cff;
        }
      }
      .color-list {
        display: flex;
        .list-item {
          .item-bg {
            width: 0.0520833rem;
            height: 0.0520833rem;
            &.item-bg-1 {
              background: #d7f9fe;
            }
            &.item-bg-2 {
              background: #b5e3f8;
            }
            &.item-bg-3 {
              background: #7ebff0;
            }
            &.item-bg-4 {
              background: #4fa1e9;
            }
          }
        }
      }
      .no-data {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #999;
        height: 100%;
      }
    }
  }
}
</style>
