<template>
  <div class="bar-chart" :id="idName"></div>
</template>

<script>
import * as echarts from "echarts";
import { myEchartsFontSize } from "@/utils";
export default {
  props: [
    "xAxisList",
    "yAxisList",
    "title",
    "idName",
    "isOrientation",
    "color",
    "type",
  ],
  data() {
    return {
      barChart: "",
    };
  },
  methods: {
    async getBarChart() {
      console.log(this.yAxisList);
      let _this = this;
      this.barChart = echarts.init(document.getElementById(_this.idName));
      let option = {
        // title: {
        //   text: "承担各级项目（件）",
        // },
        barWidth: myEchartsFontSize(14, 1) + "px",
        legend: {
          bottom: 0,
          data: [],
          textStyle: {
            fontSize: myEchartsFontSize(14, 1), // 设置图例文字的大小为14
          },
        },
        color: _this.color
          ? _this.color
          : new echarts.graphic.LinearGradient(0, 1, 0, 0, [
              { offset: 0, color: "#109FFF" },
              { offset: 1, color: "#B0F0FF" },
            ]),
        grid: {
          left: _this.isOrientation ? "10%" : "0",
          top: "10%",
          right: _this.isOrientation ? "5%" : "2%",
          bottom: "4%",
          containLabel: true,
        },
        xAxis: _this.isOrientation
          ? [
              {
                type: "value",
                axisLabel: {
                  fontSize: myEchartsFontSize(14, 1), // 设置纵坐标字体大小
                },
              },
            ]
          : [
              {
                type: "category",
                data: _this.xAxisList,
                axisLabel: {
                  fontSize: myEchartsFontSize(14, 1), // 设置纵坐标字体大小
                },
              },
            ],
        yAxis: _this.isOrientation
          ? [
              {
                type: "category",
                data: _this.yAxisList,
                axisLabel: {
                  fontSize: myEchartsFontSize(14, 1), // 设置纵坐标字体大小
                },
              },
            ]
          : [
              {
                type: "value",
                axisLabel: {
                  fontSize: myEchartsFontSize(14, 1), // 设置纵坐标字体大小
                },
              },
            ],
        itemStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
              { offset: 0, color: "#B0F0FF" },
              { offset: 1, color: "#109FFF" },
            ]),
          },
        },
        tooltip: {
          trigger: "axis",
          type: "shadow",
          renderModel: "html",
          confine: true,
          axisPointer: {
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'|'cross' cross为混合显示
            shadowStyle: {
              // color: "rgba(205, 205, 205, 0.3)",
            },
          },

          extraCssText: `width:${myEchartsFontSize(
            200,
            1
          )}px; height: ${myEchartsFontSize(
            100,
            1
          )}px; white-space: pre-wrap;border:1px solid #ccc;`,
          backgroundColor: "#ffffff",
          textStyle: {
            // 提示框浮层的文本样式。
            color: "#000",
            fontStyle: "normal",
            fontWeight: "normal",
            fontFamily: "sans-serif",
            fontSize: myEchartsFontSize(14, 1),
          },
          padding: myEchartsFontSize(10, 1), // 提示框浮层内边距，
          legend: {
            show: true,
          },
          formatter: function (arg) {
            return arg[0].name + "<br/>" + arg[0].data + "人";
          },
        },
        series: {
          data: _this.isOrientation ? _this.xAxisList : _this.yAxisList,
          type: "bar",
          label: {
            show: true, // 显示文字
            position: _this.isOrientation ? "inside" : "top", // 在柱状图上方显示
            // 可以通过 formatter 自定义显示的文本
            formatter: "{c}", // {c} 表示数据值
            textStyle: {
              fontSize:myEchartsFontSize(14, 1), // 标签字体大小
            },
          },
        },
      };
      if (_this.type == "western") {
        option.color = new echarts.graphic.LinearGradient(0, 1, 0, 0, [
          { offset: 0, color: "#3E60FF" },
          { offset: 1, color: "#C9C0FF" },
        ]);
        option.grid.bottom = "2%";
        option.barWidth = myEchartsFontSize(35, 1) +'px';
        option.xAxis[0].axisLabel = {
          fontSize: myEchartsFontSize(14, 1),
          formatter: function (value) {
            // 使用正则表达式来判断是否需要换行
            var ret = "";
            var maxLength = 3; // 设定最大显示长度
            var valLength = value.length; // 当前文本长度
            var rowN = Math.ceil(valLength / maxLength); // 需要换行的行数
            if (rowN > 1) {
              for (var i = 0; i < rowN; i++) {
                var temp = ""; // 每次截取的字符串
                var start = i * maxLength; // 开始截取的位置
                var end = start + maxLength; // 结束截取的位置
                // 这里也可以用 slice 替代 substring
                temp = value.substring(start, end) + "\n";
                ret += temp; // 拼接生成最后的字符串
              }
              return ret;
            } else {
              return value;
            }
          },
        };
      }
      this.barChart.setOption(option);
      // this.$nextTick(() => {
      //   window.onresize = function () {
      //     setTimeout(() => {
      //       _this.barChart.resize();
      //     }, 100);
      //   };
      // });
      window.addEventListener("resize", () => {
        if (_this.barChart) {
          _this.barChart.resize();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.bar-chart {
  width: 100%;
  height: 100%;
}
</style>
