<template>
  <div :id="idName" class="pie-chart"></div>
</template>

<script>
import * as echarts from "echarts";
import { myEchartsFontSize } from "@/utils";
export default {
  props: ["data", "title", "idName", "isSolid", "sourceType"],
  data() {
    return {
      pieChart: "",
      legendData: [],
      count: 0,
    };
  },
  methods: {
    // 获取饼图
    async getCircleChart() {
      let _this = this;
      console.log("123", myEchartsFontSize(12, 1));
      if (_this.sourceType == 2) {
        _this.legendData = ["初诊", "复诊", "住院", "随访"];
      } else {
        _this.legendData = this.data.map((chr) => {
          return chr.name;
        });
      }

      this.data.forEach((ele) => {
        this.count = parseInt(this.count) + parseInt(ele.value);
      });
      console.log(_this.legendData);
      this.pieChart = echarts.init(document.getElementById(_this.idName));
      let option = {
        color: ["#5B8FF9", "#5AD8A6", "#B165BE", "#F6BD16", "#E8684A"],
        title: {
          left: _this.isSolid ? "4%" : "-2%",
          top: "20%",
          textStyle: {
            color: "#333",
          },
        },
        tooltip: {
          trigger: "item",
          width: myEchartsFontSize(100, 1), // 设置提示框的宽度
          height: myEchartsFontSize(60, 1), // 设置提示框的高度
          textStyle: {
            fontSize: myEchartsFontSize(14, 1), // 设置字体大小为14
          },
          // position: ['-10%', '10%'],
        },
        legend: {
          y: "center",
          right: _this.isSolid ? "0%" : "3%",
          orient: "vertical",
          data: _this.legendData,
          icon: "circle",
          itemWidth: myEchartsFontSize(10, 1), //图例图标的宽度
          itemHeight: myEchartsFontSize(10, 1), //修改icon图形大小
          textStyle: {
            fontSize: myEchartsFontSize(14, 1), // 设置图例文字的大小为14
            padding: [0, myEchartsFontSize(3, 1)], //文字与图形之间的左右间距
          },
          itemGap: myEchartsFontSize(10, 1), //图例图标与文字间的间距
        },
        series: [
          {
            type: "pie",
            radius: _this.isSolid ? ["60%", "0%"] : ["40%", "80%"],
            center: _this.isSolid ? ["45%", "50%"] : ["30%", "50%"],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 0,
              borderColor: "#fff",
              borderWidth: myEchartsFontSize(2, 1),
              fontSize: myEchartsFontSize(14, 1),
            },
            label: {
              show: false,
              position: "center",
              formatter: _this.isSolid
                ? ""
                : _this.title + "\n\r" + _this.count,
              // normal: {
              //   formatter: _this.title+'\n\r'+"{c}",
              //   position: "inner",
              //   color: "#333",
              // },
            },
            // emphasis: {
            //   label: {
            //     show: true,
            //     fontSize: "12",
            //     fontWeight: "bold",
            //   },
            // },
            labelLine: {
              show: false,
            },

            data: _this.data,
          },
        ],
        graphic: {
          type: "text",
          left: _this.sourceType == 2 ? "30%" : "20%", // 水平居中
          top: "center", // 垂直居中
          style: {
            text: _this.isSolid ? "" : _this.title + "\n\r" + _this.count, // 显示的文字
            textAlign: "center",
            fill: "#000", // 文字颜色
            fontSize: myEchartsFontSize(12, 1),
          },
        },
      };
      if (_this.sourceType == "2") {
        option.series[0].center = ["40%", "50%"];
      }
      if (_this.isSolid) {
        option.series[0].label = {
          position: "inner",
          fontSize: myEchartsFontSize(12, 1),
          color: "#fff",
          formatter: "{c}",
        };
      }
      this.pieChart.setOption(option);
      this.$nextTick(() => {
        //   window.onresize = function () {
        //     setTimeout(() => {
        //       _this.pieChart.resize();
        //     }, 100);
        //   };
      });
      window.addEventListener("resize", () => {
        if (this.pieChart) {
          this.pieChart.resize();
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.pie-chart {
  width: 100%;
  height: 100%;
}
</style>
